// assets
import userIcon from '../assets/images/icons/equalizer.svg';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const users = {
  id: 'setting',
  type: 'group',
  children: [
    {
      id: 'setting',
      type: 'item',
      url: '/setting',
      role: [1, 2, 3],
      icon: userIcon,
      title: 'Settings',
      breadcrumbs: false
    },
  ]
};

export default users;
