import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "utils/axiosInstance"
import { addAdminUrl, getAllAdminUrl } from "utils/routesUrl"

const initialState = {
    adminData: [],
    loading: false,
    error: null,
    success: false
}



export const getAdmin = createAsyncThunk(getAllAdminUrl, async (data) => {

    try {
        const response = await axiosInstance({
            url: getAllAdminUrl,
            method: "GET",
        })
        return response.data;
    } catch (error) {
        return error
    }
})
export const addAdmin = createAsyncThunk(addAdminUrl, async (data) => {

    try {
        const response = await axiosInstance({
            url: addAdminUrl,
            method: "POST",
            data
        })
        return response;
    } catch (error) {
        return error
    }
})

const adminSlice = createSlice({
    name: 'adminData',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getAdmin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getAdmin.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.adminData = action.payload
        },
        [getAdmin.rejected]: (state, action) => {
            state.loading = false
            state.success = false
        },
        [addAdmin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [addAdmin.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            // state.adminData = action.payload
        },
        [addAdmin.rejected]: (state, action) => {
            state.loading = false
            state.success = false
        },
    },

})

export default adminSlice.reducer
// export const {  } = authSlice.actions