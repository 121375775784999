import { Helmet } from "react-helmet-async";

const HelmetTitle = ({title}) =>{
    return (
        <Helmet>
            <title>{title} | Cutiee</title>
        </Helmet>
    )
}

export default HelmetTitle;