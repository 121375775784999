import React from 'react'
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { Grid, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const TimeRange = ({value, endDateChange, startDateChange}) => {
  return (
    <>
        <Grid container justifyContent='space-between' className='date-range-field'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={5}>
                    <TimeField
                    onChange={(e)=>startDateChange(e)}
                    value={value.startDate} />
                </Grid>
                <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Typography fontSize='24px' >-</Typography></Grid>
                <Grid item xs={5}>
                    <TimeField
                    onChange={(e)=>endDateChange(e)}
                    value={value.endDate} />
                </Grid>
            </LocalizationProvider>
        </Grid>
    </>
  )
}

export default TimeRange