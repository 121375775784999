import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Tab } from "@mui/material";
import { CardBlocks, HelmetTitle } from 'views/components';
import globalStylesCss from "globalStylesCss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TabContext, TabList, TabPanel} from '@mui/lab';
import { userGetById } from 'store/slices/userSlice';
import { useTheme } from '@emotion/react';
import { get } from 'lodash';
import UserPersonal from './userPersonal';
import Cutiees from './cutiees';
import { displaySuccessAlert } from 'utils';

const AddUser = () => {
    const classes = globalStylesCss();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme();
    const params = useParams()
    const id = params?.id || ''
    const [value, setValue] = useState('1');
    const [cutieesData, setCutieesData] = useState([])
    const userData = useSelector((state => state.usersInfo))

    useEffect(() => {
        if(id){
            handleGetUserApi()
        }
    }, [id])

    useEffect(() => {
        if (id) {
            setCutieesData(get(userData, 'singleUserData.cutiees'))
        } 
    }, [id,userData])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRefreshApi = () =>{
        dispatch(userGetById(id))
    }

    const handleGetUserApi = () => {
        dispatch(userGetById(id)).then((res) =>{
            if(get(res, 'payload.response')){
                displaySuccessAlert(dispatch, 'error',get(res, 'payload.response.data.message') || get(res, 'payload.response.data.title'))
            }
        })

    }
    return (
        <>
            <HelmetTitle title='User Profile' />
            <Grid container className={`${classes.secondmainrow} secondmainrow`} >
                <Grid item xs={12} sm={10} md={10} lg={8} xl={5} className='form-container'>
                    <Grid item xs={6} mb='22px' >
                        <Typography variant="h2" >{id ? 'User Profile' : 'New User'}</Typography>
                    </Grid>
                    <CardBlocks className="adduser-card" title="Add  Client" nomargin >
                        <Box sx={{ width: '100%' }} >
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: '#EFEFEF' }}>
                                    <TabList onChange={handleChange} className='tabs-wrapper'>
                                        <Tab label="Personal" className={classes.tabListStyle} value="1" />
                                        <Tab label={id ? `Cutiees (${get(cutieesData, 'length') ? get(cutieesData, 'length') : 0})` : 'Cutiees'} className={classes.tabListStyle} value="2" />
                                        <Tab label="Billing" disabled={true} className={classes.tabListStyle} value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box className="user-pannel">
                                        <UserPersonal userData={userData} params={params} classes={classes} dispatch={dispatch} navigate={navigate}/>
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2" className='cutiee-panel'>
                                    <Box className="user-pannel">
                                        <Cutiees userData={userData} params={params} classes={classes} dispatch={dispatch} navigate={navigate} theme={theme} handleRefreshApi={handleRefreshApi} />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </CardBlocks>
                </Grid>
            </Grid>
        </>
    );
};

export default AddUser