import { Button, CircularProgress, Grid } from "@mui/material"
import { Box } from "@mui/system"
import globalStylesCss from "globalStylesCss"
import { useTheme } from '@emotion/react';
    const ActionButton = ({ disabled, title, color, size, type, variant, cancel, isUserBtn, isLoading, ...other }) => {
    const classes = globalStylesCss()
    const theme = useTheme();
    return (
        <>
            <Grid item className={`${classes.BtnContainer} action-btn-wrapper`} >
                <Button sx={{ "&:hover": { backgroundColor: isUserBtn ? theme.palette.secondary.dark : '' } }} className={isUserBtn ? classes.userBtnColor : cancel  ? classes.cancelbtn : classes.btnColor} disableElevation disabled={disabled} fullWidth size={size} type={type} variant={variant} {...other}>
                  {isLoading ? <Box sx={{ display: 'flex', alignItems: 'center'}}>{title} <Box sx={{marginLeft: '12px', lineHeight: '10px'}}><CircularProgress size="1.1rem" sx={{ color: isUserBtn ? theme.palette.primary.main : theme.palette.secondary.dark}} /></Box></Box>  : title}
                </Button>
            </Grid>
        </>
    )
}
export default ActionButton