import { createSlice } from '@reduxjs/toolkit'


const alertSlice = createSlice({
    name: 'snackBar',
    initialState: {
        snackbarOpen: false,
        snackbarType: "",
        snackbarMessage: ""
    },
    reducers: {
        displayAlert: (state, action) => {
            const { snackbarOpen, snackbarMessage, snackbarType } = action.payload;
            state.snackbarOpen = snackbarOpen;
            state.snackbarType = snackbarType;
            state.snackbarMessage = snackbarMessage;
        }
    },
})
export const { displayAlert } = alertSlice.actions
export default alertSlice.reducer