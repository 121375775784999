import React, {useState, useEffect} from 'react'
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addDaycareCenter, clearStepData, thirdStep, updateDayCare } from 'store/slices/dayCareSlice';
import { get } from 'lodash';
import { addedSucc, displaySuccessAlert, updatedSucc } from 'utils';
import { ActionButton, SwitchButton } from 'views/components';


const DayCareSettings = ({handleStepChange, classes, dispatch, params}) => {
    const [showList, setShowList] = useState(true);
    const [syncNewInfo, setSyncNewInfo] = useState(true);
    const [coordinates, setCoordinates] = useState({
        latitude: '',
        longitude: ''
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const id = params?.id || '';
    const dayCareInfo = useSelector(state => state.dateCareInfo)
    const toggleShowListButton = () => {
        dispatch(thirdStep({
            'ShowInDaycare': !showList,
            'SyncInfo': syncNewInfo
        }))
        setShowList(!showList);
    }
    const toggleSyncInfoButton = () => {
        dispatch(thirdStep({
            'ShowInDaycare': showList,
            'SyncInfo': !syncNewInfo
        }))
        setSyncNewInfo(!syncNewInfo);
    }
    const handleBack = () => {
        dispatch(thirdStep({
            'ShowInDaycare': showList,
            'SyncInfo': syncNewInfo
        }))
        handleStepChange("", '2')
    }

    const addCenter = () => {
        setLoading(true);
        let data = {
            "name": dayCareInfo.firstStepInfo.name,
            "latitude": coordinates.latitude,
            "longitude": coordinates.longitude,
            "mobNo": dayCareInfo.firstStepInfo.mobNo,
            "emailId": dayCareInfo.firstStepInfo.emailId,
            "business_status": "",
            "address": dayCareInfo.firstStepInfo.address,
            "rating": dayCareInfo.secondStepInfo.ratings,
            "state": dayCareInfo.firstStepInfo.state,
            "city": dayCareInfo.firstStepInfo.city,
            "country": dayCareInfo.firstStepInfo.country,
            "Zip": dayCareInfo.firstStepInfo.zip,
            "website": dayCareInfo.secondStepInfo.website,
            "about": dayCareInfo.secondStepInfo.about,
            "centerType": dayCareInfo.firstStepInfo.type ? dayCareInfo.firstStepInfo.type : 0,
            'showInDaycare': showList,
            'syncInfo': syncNewInfo
        }
        try {
            let addFunction;
            if(id){
                data.id = id
                addFunction = updateDayCare(data)
            } else {
                addFunction = addDaycareCenter(data)
            }
            dispatch(addFunction).then((res) => {
                if (get(res, "payload.success")) {
                    let message =  id ? `Daycare ${updatedSucc}` : `Daycare ${addedSucc}`;
                    displaySuccessAlert(dispatch, 'success', message)
                    navigate('/centers')
                    dispatch(clearStepData());
                } else if (get(res, "payload.data.errors")) {
                    displaySuccessAlert(dispatch, 'error',get(res, 'payload.data.message') || get(res, 'payload.data.title'))
                } else {
                    displaySuccessAlert(dispatch, 'error',get(res, 'payload.data.message'))
                }
            })
        } catch (err) {
            displaySuccessAlert(dispatch, 'error', err.message? err.message: 'Something went wrong')
        } finally {
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(dayCareInfo.thirdStepInfo && id){
            setShowList(dayCareInfo.thirdStepInfo.ShowInDaycare)
            setSyncNewInfo(dayCareInfo.thirdStepInfo.SyncInfo)
        }
        if(dayCareInfo.firstStepInfo.coordinates){
            const array =  dayCareInfo.firstStepInfo.coordinates.replace(", ", ",").split(",")
            
            let latitude = array[0];
            let longitude = array[1];
            setCoordinates({
                latitude: latitude,
                longitude: longitude
            });
        }
    }, [dayCareInfo])

  return (
    <Box>
        <Box className={`${classes.settingBox} controls center-settings`}>
            <Typography variant='subtitle1' fontWeight={300} className='settings-title'>Show in daycare list</Typography>
            <SwitchButton type="button" active={showList} clicked={toggleShowListButton} ></SwitchButton>
        </Box>    
        <Box className={`${classes.settingBox} controls center-settings`}>
            <Typography variant='subtitle1' fontWeight={300} className='settings-title'>Sync new information</Typography>
            <SwitchButton type="button" active={syncNewInfo} clicked={toggleSyncInfoButton} ></SwitchButton>
        </Box>    
        <Grid>
            <Grid container className={classes.updateUserBtnContainer} mt={3}>
                <Box display='flex'>
                    <ActionButton color='error' cancel={true} title='Back' onClick={handleBack} variant='contained' size="large" />
                    <ActionButton color='secondary' cancel={false} title='Save changes' isLoading={loading} disabled={loading} onClick={addCenter} disableElevation fullWidth size="large" variant="contained" />
                </Box>
            </Grid>
        </Grid>
    </Box>
  )
}

export default DayCareSettings