import { Chip, Stack, Typography } from '@mui/material';
import React from 'react'

const FilterList = ({ filters }) => {
    const getChipColor = (column) => {
        if (column == 'country') {
            return "info"
        } else if (column == "state") {
            return "success"
        } else if (column == "city") {
            return "warning"
        } else {
            return ""
        }
    }
    return (
        <>

            <Stack
                direction='row'
                gap={1}
                flexWrap='wrap'

            >
                {
                    filters.length > 0 && filters.map(filter => (
                        <Chip
                            color={getChipColor(filter?.columnName)}
                            variant="soft"
                            label={filter?.name}
                            size='small'
                        />
                    ))
                }
            </Stack>
        </>
    )
}

export default FilterList