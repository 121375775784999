import React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { InputLabel } from '@mui/material';
import globalStylesCss from 'globalStylesCss';
import { dateFormat } from 'utils';
export default function DatePickerComponent({ onChange, date, width, helperText }) {
    const classes = globalStylesCss()
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}  >
            <div >
                <InputLabel
                    htmlFor="password-field" sx={{ color: '#080808', marginBottom: '4px' }}>Date of Birth</InputLabel>
                <DatePicker

                    sx={{
                        width: width,
                    }}
                    className={classes.datePickerStyle}
                    value={dayjs(date)}
                    onChange={(newValue) => {
                        onChange(newValue);
                    }}
                    renderInput={(params) => {
                        return <TextField size="small"{...params} readOnly />
                    }}
                    slotProps={{
                        textField: {
                          helperText: helperText,
                        },
                    }}
                    disableFuture={true}
                    format='MMM DD YYYY'
                />
                {/* <FormHelperText
                    sx={{ color: 'red', marginLeft: '10px' }}
                >
                    {helperText}
                </FormHelperText> */}
            </div>
        </LocalizationProvider >
    );
}