import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "utils/axiosInstance"
import { getUserAnalyticsUrl } from "utils/routesUrl"

const initialState = {
    userAnalyticsData: [],
    loading: false,
    error: null,
    success: false
}

export const getUserAnalytics = createAsyncThunk(getUserAnalyticsUrl, async (data) => {

    try {
        const response = await axiosInstance({
            url: getUserAnalyticsUrl,
            method: "GET",
        })
        return response.data;
    } catch (error) {
        return error
    }
})

const userAnalyticsSlice = createSlice({
    name: 'userAnalyticsData',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getUserAnalytics.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getUserAnalytics.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.userAnalyticsData = action.payload
        },
        [getUserAnalytics.rejected]: (state, action) => {
            state.loading = false
            state.success = false
        },
    },

})

export default userAnalyticsSlice.reducer