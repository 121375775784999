import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { displayAlert } from "store/slices/alertSlice";

const Alert = React.forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alerts() {
    const snackbarState = {
        vertical: 'top',
        horizontal: 'right',
    }
    const dispatch = useDispatch()
    const { snackbarOpen, snackbarType, snackbarMessage } = useSelector(state => state.alertSlice);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(displayAlert(false, snackbarType, snackbarMessage));
        const snackbarData = {
            snackbarOpen: false,
            snackbarType,
            snackbarMessage
        }
        dispatch(displayAlert(snackbarData))
    };

    const { vertical, horizontal } = snackbarState;

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={snackbarType} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
