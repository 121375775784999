import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "utils/axiosInstance"
import { delDayCareUrl } from "utils/routesUrl"

const initialState = {
    loading: false,
    error: null,
    success: false
}

export const removeDayCare = createAsyncThunk(delDayCareUrl, async (data) => {

    try {
        const response = await axiosInstance({
            url: delDayCareUrl,
            method: "DELETE",
            data
        })
        response.data.id = data?.Id
        return response.data;
    } catch (error) {
        return error
    }
})
const cutieeSlice = createSlice({
    name: 'removeDaycare',
    initialState,
    reducers: {
    },
    extraReducers: {
        [removeDayCare.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [removeDayCare.fulfilled]: (state) => {
            state.loading = false
            state.success = true
        },
        [removeDayCare.rejected]: (state) => {
            state.loading = false
            state.success = false
        },
    },

})

export default cutieeSlice.reducer
// export const {  } = authSlice.actions