import React from "react";
import { Box, Card, Divider, CardHeader, CardContent } from "@mui/material";
const CardBlocks = ({ title, children, nomargin,isCardShow, ...other }) => {
    return (
        <Box component="span" m={nomargin ? 0 : 1} {...other} >
               <Card className="card-wrapper">
                {isCardShow ? 
                <>
                <CardHeader  titleTypographyProps={{fontSize:' 12px !important',fontWeight:'400',color:'grey' }}  title={title} />
                <Divider />
                </>
                : null}
                <CardContent>{children}</CardContent>
            </Card>
        </Box>
    );
};

export default CardBlocks;