import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,

    Typography
} from '@mui/material';
import settingIcon from '../../../../assets/images/icons/equalizer.svg';
import { Link } from 'react-router-dom';
import globalStylesCss from 'globalStylesCss';
import SvgIcons from 'views/components/svgIcons';

// ==============================|| PROFILE MENU ||============================== //

const Setting = () => {
    const classes = globalStylesCss()
    return (
        <>
            <Box >
                <Link to='/Setting' className={classes.linkTextDecoration}  >
                    <List
                        component="nav"
                        sx={{
                            width: '100%',
                        }}
                    >
                        <ListItemButton
                            sx={{ padding: '0 10px' }}
                        >
                            <ListItemIcon sx={{ mr: '6px' }}>
                                <SvgIcons src={settingIcon} style={{ color: '#adadad', height: '18px' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="navLink" >Settings</Typography>} />
                        </ListItemButton>
                    </List>
                </Link>
            </Box >
        </>
    );
};

export default Setting;
