import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/axiosInstance";
import Cookies from 'universal-cookie';
import { adminLoginUrl, forgotPasswordUrl } from "utils/routesUrl";
const cookies = new Cookies()
const initialState = {
    loading: false,
    adminInfo: {}, // for user object
    adminToken: null, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.
}


export const adminLogin = createAsyncThunk(adminLoginUrl, async (data) => {
    try {
        const response = await axiosInstance({
            url: adminLoginUrl,
            method: "POST",
            data: data

        })
        // localdata set
        response.data.role = 2
        return response.data
    } catch (error) {
        console.log("error login", error)
        return error?.response
    }
}
)

export const forgotPasswordAdmin = createAsyncThunk(forgotPasswordUrl, async (data) => {
    try {
        const response = await axiosInstance({
            url: forgotPasswordUrl,
            method: "POST",
            data
        })
        return response.data
    } catch (error) {
        return error.response
    }
})


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logoutAdmin: (state) => {
            state.success = false
        },
        insertUserData: (state, action) => {
            state.adminInfo = action.payload
        }
    },
    extraReducers: {
        [adminLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [adminLogin.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.adminInfo = action.payload.user
            if (action?.payload?.token) {
                cookies.set("token", action.payload.token, { path: '/', maxAge: 31536000 })
                cookies.set("userData", action.payload)
                cookies.set("adminData", action.payload.user)
            }
        },
        [adminLogin.rejected]: (state, action) => {
            state.loading = false
            state.success = false
            // state.error = get(action.payload, 'response.data')
        },
        [forgotPasswordAdmin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [forgotPasswordAdmin.fulfilled]: (state, action) => {
            state.loading = false
        },
        [forgotPasswordAdmin.rejected]: (state, action) => {
            state.loading = false
            state.success = false
        },
    },
})

export default authSlice.reducer
export const { logoutAdmin, insertUserData } = authSlice.actions