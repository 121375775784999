import { useState } from "react"
import TextInput from "./textInput"
import { Box, debounce } from "@mui/material"
import { useEffect } from "react"
import { useMemo } from "react"
const SearchFilter = ({ paginationModel, searchData, handleFilter, handleSearchFilter, isApiSearch, isClearSearchInput }) => {
 
    const [search, setSearch] = useState('')
    const [initialRender, setInitialRender] = useState(true);
    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
        } else if (isApiSearch) {
            fetch(search)
        }
    }, [search])
   
    const handleChange = (e) => {
        setSearch(e.target.value)
        let searchText = e.target.value
        if (e.target.value.length > 1) {
            if(isApiSearch){
                // handleSearchFilter(searchText)
            } else {
                const data = searchData.filter(data => Object.keys(data).some(key => {
                    return String(data['firstName']).toLowerCase().includes(searchText.toLowerCase()) || String(data['emailId'] ).toLowerCase().includes(searchText.toLowerCase())
                }))
                handleFilter(data)
            }
        } else if(e.target.value.length == 0) {
            if(isApiSearch){
                // handleSearchFilter('')
            } else {
                handleFilter(searchData)
            }
        } else {
            handleFilter(searchData)
        }
    }
    const fetch = useMemo(
        () =>
            debounce(async (request, callback) => {
                try {
                    handleSearchFilter(request)
                }
                catch (error) {
                    console.log("Error ==>", error)
                } 
            }, 700),
        [paginationModel],
    );
    useEffect(() =>{
        if(isClearSearchInput){
            setSearch('')
        }
    }, [isClearSearchInput])
    return (
        <>
            <Box className='search-filter'>
                <TextInput
                    type="text"
                    isLabelShow={false}
                    isSearchLabelShow={true}
                    value={search}
                    name="search"
                    size='small'
                    onChange={handleChange}
                    label="Search..."
                    className='search-field'
                    sx={{
                        '& .MuiInputBase-input ': {
                            padding: '14px 16px !important',
                            borderRadius: '20px !important',
                            border: 'none',
                            boxShadow: '0px 2px 10px rgba(0,0,0,0.05)'
                        },
                        '& fieldset': {
                            border: 'none'
                        },
                        '& .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root': {
                            borderRadius: '20px !important'
                        }
                    }}
                />
            </Box>
        </>
    )
}
export default SearchFilter