import { InputLabel, TextField } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const TextInput = ({ name, onBlur, value,onKeyUp, label, onInputChange, type, sizeSmall, labelColor, isLabelShow, className,isSearchLabelShow, min, max, disabled, ...other }) => {
    const classes = useStyles()
    return (
        <>
            
        {!isLabelShow && !isSearchLabelShow ? <InputLabel sx={{ color: labelColor ? 'red' :'#080808', fontSize: '18px'}} shrink htmlFor="bootstrap-input">
        {label}
      </InputLabel> : null }
        <TextField
            type={type || "text"}
            value={value}
            // label={!isSearchLabelShow ? '': label}
            placeholder={!isSearchLabelShow ? '': label}
            name={name}
            onChange={onInputChange}
            variant="outlined"
            fullWidth
            size={sizeSmall ? "small" : 'medium'}
            onBlur={onBlur}
            {...other}
            className={!isSearchLabelShow ? classes.label: classes.searchLbel}
            disabled={disabled}
            autoComplete="off"
            inputProps={{
                min: min,
                max: max,
              }}
        />
        </>
    );
};

export default TextInput;
const useStyles = makeStyles((theme) => ({
    
    label: {
        '& label.Mui-focused': {
            color: theme.palette.input.main,
        },
        "&:hover label": {
            color: theme.palette.input.main,
        },
        '& .MuiOutlinedInput-root': {
            '& Input': {
                height: '12px',
                color: '#434343'
            },
            '& fieldset': {
                borderColor: theme.palette.input.light,
                borderRadius: '0px',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.input.light,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.input.light,

            },
        },
    },
    searchLbel: {
        '& label.Mui-focused': {
            color: theme.palette.input.main,
        },
        '& .MuiOutlinedInput-root': {
            '& Input': {
                height: '12px',
                backgroundColor: '#ffffff'
            },
            '& fieldset': {
                borderColor: theme.palette.input.light,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.input.light,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.input.light,

            },
        },
    }
}));