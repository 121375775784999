import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Grid, Box, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { AddCenterAdvancedValidateSchema } from 'utils/validation';
import { secondStep } from 'store/slices/dayCareSlice';
import { ActionButton, TextInput, TimeRange } from 'views/components';

const initialValues = {
    website: '',
    ratings: 0,
    about: '',
}

const Advanced = ({handleStepChange, classes, dispatch}) => {
    const [data, setData] = useState(initialValues);
    const fieldValues = useSelector((state)=> state.dateCareInfo.secondStepInfo)

    const [timeRange, setTimeRange] = useState([
        {startDate: '',endDate: '', label: 'Sunday'},
        {startDate: '',endDate: '', label: 'Monday'},
        {startDate: '',endDate: '', label: 'Tuesday'},
        {startDate: '',endDate: '', label: 'Wednesday'},
        {startDate: '',endDate: '', label: 'Thursday'},
        {startDate: '',endDate: '', label: 'Friday'},
        {startDate: '',endDate: '', label: 'Saturday'},
    ]);

    const handleTimeRangeChange = (e,index,type) => {
        // console.log("--",e, index, type);
    }
    
    useEffect(()=>{
        if(fieldValues){
            setData(fieldValues);
        }
    }, [fieldValues])

    return (
        <>
            <Formik
                initialValues={data}
                validationSchema={AddCenterAdvancedValidateSchema}
                enableReinitialize={true}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    dispatch(secondStep(values));
                    handleStepChange("",'3')
                }}
            >

                {({ errors, handleBlur, handleChange, handleSubmit, setValues, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container justifyContent='space-between' className='adduser-form'>
                            <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                <Box>
                                    <TextInput
                                        type="text"
                                        value={values.website}
                                        name="website"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="Website"
                                        error={touched.website && Boolean(errors.website)}
                                        helperText={touched.website && errors.website}
                                    />
                                </Box>
                                <Box>
                                    <TextInput
                                        type="number"
                                        value={values.ratings}
                                        name="ratings"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="Ratings"
                                        min='0'
                                        max='5'
                                        error={touched.ratings && Boolean(errors.ratings)}
                                        helperText={touched.ratings && errors.ratings}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12} className='adduser-fields-wrapper date-range-field-wrapper'>
                                <Box>
                                    <TextInput
                                        type="text"
                                        value={values.about}
                                        name="about"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="About"
                                        error={touched.about && Boolean(errors.about)}
                                        helperText={touched.about && errors.about}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel sx={{ color: '#000', fontSize: '18px' }} shrink>Hours</InputLabel>
                                    {timeRange.map((item,index)=>(
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <InputLabel className='date-range-field-label' sx={{ color: '#999999 !important', fontSize: '18px', minWidth: '60px', maxWidth: '80px' }} shrink>{item.label}</InputLabel>
                                            <TimeRange endDateChange={(e)=>handleTimeRangeChange(e,index,'end')} startDateChange={(e)=>handleTimeRangeChange(e,index,'start')} value={item} />
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                            {/* <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                
                            </Grid> */}
                        </Grid> 
                        <Grid>
                            <Grid container className={classes.updateUserBtnContainer} mt={2}>
                                <Box display='flex'>
                                    <ActionButton color='error'  cancel={true} title='Back' onClick={()=>handleStepChange("", '1')} variant='contained' size="large" />
                                    <ActionButton color='secondary' cancel={false} title='Next' disableElevation fullWidth size="large" type="submit" variant="contained" />
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default Advanced