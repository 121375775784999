export const adminLoginUrl = 'User'
export const forgotPasswordUrl = 'User/ForgotPassword'
export const getAllAdminUrl = 'User/GetAdmin'
export const addAdminUrl = 'User/AddAdmin'
// export const cutieeDeleteUrl = 'Cutiee/Delete'
export const cutieeDeleteUrl = 'User/DeleteCutieeByAdmin'
export const cutieeUpdate = 'Cutiee/Edit'
export const userGetUrl = 'User/Get'
export const userAddUrl = 'User/Add'
export const userGetByIdUrl = 'User/GetById'
export const userUpdateUrl = 'User/Edit'
export const userDeleteUrl = 'User/Delete'
export const adminResetPasswordUrl = 'User/ResetPassword'
export const getActivityUrl = 'activities/report'
export const addDaycare = 'Daycares'
export const getUserAnalyticsUrl = 'Dashboards/Analytics'
export const getCentersUrl = 'Daycares/GetCenters'
export const delDayCareUrl = 'Daycares/DeleteDaycare'
export const getByIdDayCare = 'Daycares/GetById'
export const updateDayCareUrl = 'Daycares/EditDaycare'
export const getDayCareFilterUrl = 'Daycares/GetFilterData'
