import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import { Navigate } from 'react-router';
import RequiredPath from './RequiredPath';
import AddUser from 'views/users/component';
import AddCenter from 'views/centers/addCenters';
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Users = Loadable(lazy(() => import('views/users/users')));
const PageNotFound = Loadable(lazy(() => import('views/pageNotfound/pagenotfound')));
const Setting = Loadable(lazy(() => import('views/setting/setting')));
const Acitvity = Loadable(lazy(() => import('views/activity/activity')))
const Centers = Loadable(lazy(() => import('views/centers/centers')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/dashboard" />
    },
    {
      path: '/dashboard',
      element: <RequiredPath allowedRoles={[1, 2, 3]} />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardDefault />
        },
      ]
    },
    {
      path: '/users',
      element: <RequiredPath allowedRoles={[1, 2]} />,
      children: [
        {
          path: '/users',
          element: <Users />
        },
        {
          path: '/users/addUser',
          element: <AddUser />
        },
        {
          path: '/users/updateUser/:id',
          element: <AddUser />
        },
      ]
    },
    {
      path: '/activity',
      element: <RequiredPath allowedRoles={[1, 2]} />,
      children: [
        {
          path: '/activity',
          element: <Acitvity />
        },
      ]
    },
    {
      path: '/setting',
      element: <RequiredPath allowedRoles={[1, 2, 3]} />,
      children: [
        {
          path: '/setting',
          element: <Setting />
        },
      ]
    },
    {
      path: '/centers',
      element: <RequiredPath allowedRoles={[1, 2, 3]} />,
      children: [
        {
          path: '/centers',
          element: <Centers />
        },
        {
          path: '/centers/addCenter',
          element: <AddCenter />
        },
        {
          path: '/centers/updateCenter/:id',
          element: <AddCenter />
        },
      ]
    },
    {
      path: '*',
      element: <PageNotFound />
    }

  ]
};

export default MainRoutes;
