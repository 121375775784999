import * as Yup from 'yup';
import { matchIsValidTel } from 'mui-tel-input';
import { addressMin, emailRegex, invalidDate, minMsg, passwordRegex, passwordRequired, passworsMsg, ratingMax, ratingMin, required, trim, validEmail, validPhoneNumber, zipCodeMsg } from 'utils';

const emailValidation = Yup.string().required(`Email ${required}`).trim(trim).matches(emailRegex, validEmail).strict(true)
const emailValidationNotRequired = Yup.string().nullable().trim(trim).matches(emailRegex, validEmail).strict(true)
const passwordValidation = Yup.string().trim().max(255);
const regularValidation = Yup.string().min(3, minMsg).max(30, minMsg)
const phoneVlidation = Yup.string().test("test-name", validPhoneNumber,
(value) => {
    if (!value) {
        return false
    }
    if (value) {
        return matchIsValidTel(value)
    }
    return true
})

export const AdminLoginSchema = Yup.object({
    email: emailValidation,
    password: passwordValidation.required(passwordRequired)
})

export const forgetPasswordSchema = Yup.object({
    emailId: emailValidation,
})

export const restPasswordSchema = Yup.object({
    password: passwordValidation.required(passwordRequired),
    confirmPassword: Yup.string().required(`Confirm Password ${required}`).oneOf([Yup.ref('password'), null], 'Passwords does not match'),
})

export const AddAdminPersonalValidateSchema = Yup.object({
    emailId: emailValidation,
    firstName: Yup.string().min(3, minMsg).max(30, minMsg).nullable().required(`First name ${required}`).trim(trim).strict(true),
    lastName: Yup.string().min(3, minMsg).max(30, minMsg).nullable().required(`Last name ${required}`).trim(trim).strict(true),
    mobNo: phoneVlidation
})

export const AddAdminSecurityValidateSchema = Yup.object({
    oldPassword: passwordValidation.required(`Current ${passwordRequired}`),
    newPassword: passwordValidation.required(`New ${passwordRequired}`).matches(
        passwordRegex,
        passworsMsg
    ),
})

export const AddAdminValidateSchema = Yup.object({
    users: Yup.array().of(
        Yup.object().shape({
            emailId: emailValidation,
            //   password:passwordValidation.matches(passwordRegex, passworsMsg),
        })
    )
})

export const AddUserValidateSchema = Yup.object({
    emailId: emailValidation,
    firstName: Yup.string().min(3, minMsg).max(30, minMsg).required(`First name ${required}`).trim(trim).strict(true),
    lastName: Yup.string().min(3, minMsg).max(30, minMsg).required(`Last name ${required}`).trim(trim).strict(true),
    mobNo: phoneVlidation,
    password: passwordValidation.required(passwordRequired).matches(
        passwordRegex,
        passworsMsg
    ),
})

export const UpdatedUserValidateSchema = Yup.object({
    emailId: emailValidation,
    firstName: Yup.string().min(3, minMsg).max(30, minMsg).nullable().required(`First name ${required}`).trim(trim).strict(true),
    lastName: Yup.string().min(3, minMsg).max(30, minMsg).nullable().required(`Last name ${required}`).trim(trim).strict(true),
    mobNo: Yup.string().nullable().test("test-name", validPhoneNumber,
        (value) => {
            if (!value) {
                return false
            }
            if (value) {
                return matchIsValidTel(value)
            }
            return true
        }),
})

export const AddCutieeValidateSchema = Yup.object({
    firstName: regularValidation.required(`First name ${required}`).trim(trim).strict(true),
    lastName: regularValidation.required(`Last name ${required}`).trim(trim).strict(true),
    dob: Yup.date().typeError(invalidDate).required(`Date Of Birth ${required}`),
    name: Yup.string(),
    additionalInfo: Yup.string().trim(trim).strict(true),
    dropOffTime: Yup.string().required(`Drop off time ${required}`).trim(trim).strict(true),
    alertAfter: Yup.number().min(0, `Alert after ${ratingMin} 0`).max(30, `Alert after ${ratingMax} 30`).required(`Alert after ${required}`),
    trustedContactsName: Yup.array().of(Yup.string().required(`Name ${required}`)),
    trustedContactsNo: Yup.array().of(
        phoneVlidation
    ),
    emergencyContactsName: Yup.array().of(Yup.string().required(`Name ${required}`)),
    emergencyContactsNo: Yup.array().of(
        phoneVlidation
    )
})

export const AddCenterBasicValidateSchema = Yup.object({
    name: Yup.string().min(3, minMsg).required(`Name ${required}`).trim(trim).strict(true),
    type: Yup.mixed().strict(true).required(`Type ${required}`),
    mobNo: Yup.string().nullable().test("test-name", validPhoneNumber,
        (value) => {
            // if (!value) {
            //     return false
            // }
            if (value) {
                return matchIsValidTel(value)
            }
            return true
        }),
    emailId: emailValidationNotRequired,
    address: Yup.string().min(5, addressMin).required(`Address ${required}`).trim(trim).strict(true),
    city: Yup.string().nullable().required(`City ${required}`).trim(trim).strict(true),
    state: Yup.string().nullable().required(`State ${required}`).trim(trim).strict(true),
    zip: Yup.string().trim().required(`ZIP code ${required}`).matches(/^\d{5,6}$/, zipCodeMsg),
    country: Yup.string().nullable().required(`Country ${required}`).trim(trim).strict(true),
})

export const AddCenterAdvancedValidateSchema = Yup.object({
    website: Yup.string().url('URL in not valid').max(70, "URL should be under 70 characters"),
    ratings: Yup.number().min(0, `Rating ${ratingMin} 0`)
    .max(5, `Rating ${ratingMax} 5`),
    about: Yup.string().trim(trim).strict(true),
    hours: Yup.array().of(
        Yup.object().shape({
            from: Yup.date().typeError(invalidDate),
            to: Yup.date().typeError(invalidDate)
        })
    )
})