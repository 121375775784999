import React, { useState } from "react";
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormHelperText,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import clsx from "clsx";
import globalStylesCss from "globalStylesCss";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
const PasswordInput = ({ name, value, label, onInputChange, labelError, placeholder,
    helperText, ...rest }) => {
    const classes = globalStylesCss();
    const theme = useTheme()
    const useStylesClasses = useStyles()
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleAndornment = () =>{
        return (
            <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
        )
    }

    return (
            <>
                {labelError ?
                <InputLabel htmlFor="password-field" sx={{
                    color: 'red',
                    "&.Mui-focused": {
                        color: theme.palette.secondary.dark
                    },
                    "& .MuiOutlinedInput-root:hover": {
                        '& label': {
                            color: theme.palette.secondary.dark,
                        },
                    },
                }} >{label}</InputLabel> :
                    label ?
                <InputLabel htmlFor="password-field" sx={{color: '#080808', marginBottom: '4px' }} classes={useStylesClasses}>{label}</InputLabel> : null
            }
             <FormControl
            className={clsx(classes.margin, classes.textField, classes.width100)}>
        
            <OutlinedInput
                // label={label}
                id="password-field"
                type={showPassword ? "text" : "password"}
                name={name}
                value={value}
                variant="outlined"
                onChange={onInputChange}
                classes={useStylesClasses}
                placeholder={placeholder}
                endAdornment={handleAndornment()}
                labelWidth={70}
                autoComplete="off"
                {...rest}
            />
            <FormHelperText
                sx={{ color: labelError && 'error.main', marginLeft: '10px' }}
            >
                {helperText}
            </FormHelperText>
            </FormControl > 
            </>
    );
};



export default PasswordInput;
const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.input.main,
        backgroundColor: '#ffffff',
        "&.Mui-focused": {
            color: theme.palette.input.main,
            backgroundColor: '#ffffff'
        },
        "&:hover label": {
            color: theme.palette.input.main,
        },

        "& $notchedOutline": {
            borderColor: theme.palette.input.light,
            borderRadius: '0px',

        },
        "&:hover $notchedOutline": {
            borderColor: theme.palette.input.light
        },
        "&$focused $notchedOutline": {
            borderColor: theme.palette.input.light,
        },
        '& .MuiOutlinedInput-root': {
            '& Input': {
                height: '12px !important',
                backgroundColor: '#ffffff'
            },
            '& fieldset': {
                borderColor: theme.palette.input.light,
                borderRadius: '0px',
            },
        }
    },
    focused: {},
    notchedOutline: {},

}));