import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "utils/axiosInstance"
import { adminResetPasswordUrl, userAddUrl, userDeleteUrl, userGetByIdUrl, userGetUrl, userUpdateUrl } from "utils/routesUrl"

const initialState = {
    userData: [],
    singleUserData: {},
    loading: false,
    error: null,
    updateLoading: false,
    success: false
}
export const usersInfo = createAsyncThunk(userGetUrl, async (data) => {
    try {
        const response = await axiosInstance({
            url: userGetUrl,
            method: 'GET'
        })
        return response.data
    } catch (err) {
        return err.response
    }
})

export const addUser = createAsyncThunk(userAddUrl, async (data) => {
    try {
        const response = await axiosInstance({
            url: userAddUrl,
            method: "POST",
            data
        })
        return response.data
    } catch (error) {
        return error.response
    }
})

export const userGetById = createAsyncThunk(userGetByIdUrl, async (data) => {
    try {
        const response = await axiosInstance({
            url: `${userGetByIdUrl}?id=${data}`,
            method: 'GET'
        })
        return response.data
    } catch (err) {
        return err
    }
})

export const updateUser = createAsyncThunk(userUpdateUrl, async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance({
            url: userUpdateUrl,
            method: "PUT",
            data
        })
        return response.data
    } catch (error) {
        console.log("error edit users", error)
        return rejectWithValue(error?.response)
    }
})

export const removeUser = createAsyncThunk(userDeleteUrl, async (data) => {

    try {
        const response = await axiosInstance({
            url: userDeleteUrl,
            method: "DELETE",
            data
        })
        response.data.id = data?.Id
        return response.data;
    } catch (error) {
        return error
    }
})

export const adminResetPassword = createAsyncThunk(adminResetPasswordUrl, async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance({
            url: adminResetPasswordUrl,
            method: "PUT",
            data
        })
        return response
    } catch (error) {
        return error?.response
    }
})

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
    },
    extraReducers: {
        [usersInfo.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [usersInfo.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.userData = action.payload
        },
        [usersInfo.rejected]: (state, action) => {
            state.loading = false
            state.success = false
            // state.error = get(action.payload, 'response.data')
        },
        [addUser.pending]: (state) => {
            state.updateLoading = true
            state.error = null
        },
        [addUser.fulfilled]: (state, action) => {
            state.updateLoading = false
        },
        [addUser.rejected]: (state, action) => {
            state.updateLoading = false
            state.success = false
        },
        [userGetById.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userGetById.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.singleUserData = action.payload
        },
        [updateUser.pending]: (state) => {
            state.updateLoading = true
            state.error = null
        },
        [updateUser.fulfilled]: (state, action) => {
            state.success = true
            state.updateLoading = false
        },
        [updateUser.rejected]: (state, action) => {
            state.updateLoading = false
            state.success = false
        },
        [removeUser.fulfilled]: (state, action) => {
            state.success = true
        },
        [adminResetPassword.pending]: (state) => {
            state.updateLoading = true
            state.error = null
        },
        [adminResetPassword.fulfilled]: (state, action) => {
            state.updateLoading = false
            state.success = true
        },
        [adminResetPassword.rejected]: (state, action) => {
            state.updateLoading = false
            state.success = false
        },
    },

})

export default usersSlice.reducer
// export const {  } = authSlice.actions