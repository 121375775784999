import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/slices/slice';

// assets
import { IconChevronRight } from '@tabler/icons';
// styles
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
//   ...theme.typography.mainContent,
//   borderBottomLeftRadius: 0,
//   borderBottomRightRadius: 0,
//   padding: '0 !important',
//   paddingLeft: '50px !important',
//   transition: theme.transitions.create(
//     'margin',
//     open
//       ? {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen
//       }
//       : {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen
//       }
//   ),
//   [theme.breakpoints.up('md')]: {
//     marginLeft: open ? 0 : -(drawerWidth - 20),
//     width: `calc(100% - ${drawerWidth}px)`
//   },
//   [theme.breakpoints.down('md')]: {
//     marginLeft: '20px',
//     width: `calc(100% - ${drawerWidth}px)`,
//     padding: '16px'
//   },
//   [theme.breakpoints.down('sm')]: {
//     marginLeft: '10px',
//     width: `calc(100% - ${drawerWidth}px)`,
//     padding: '16px',
//     marginRight: '10px'
//   }
// }));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  // Handle left drawer
  const [leftDrawerOpened, setLeftDrawerOpened] = useState(true);
  const [windowWidth, setWindowWidth] = useState(getCurrentDimension);

  const handleLeftDrawerToggle = () => {
    setLeftDrawerOpened(!leftDrawerOpened)
  };

  function getCurrentDimension(){
    return window.innerWidth
  }

  useEffect(()=>{
    if(windowWidth && windowWidth <= 1024){
      setLeftDrawerOpened(false)
    }
  }, [windowWidth])

  return (
    <Box className='app-wrapper'>
      {/* drawer */}
      <Sidebar className={`app-sidebar ${leftDrawerOpened && windowWidth <= 1024 && 'open'}`} drawerOpen={leftDrawerOpened} windowWidth={windowWidth} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <main theme={theme} 
      // open={leftDrawerOpened} 
      className='main'>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
      </main>
    </Box>
  );
};

export default MainLayout;
