import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Cookies from 'universal-cookie';
// ==============================|| ROUTING RENDER ||============================== //
const cookies = new Cookies();
export default function ThemeRoutes() {
  const AuthenticatedRoutes = useRoutes([MainRoutes]);
  const otherRoutes = useRoutes([AuthenticationRoutes]);
  if (cookies.get('token') && cookies.get('userData')) {
    return AuthenticatedRoutes;
  } else {
    return otherRoutes;
  }
}
