
// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({drawerToggle}) => {
  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup drawerToggle={drawerToggle} key={item.id} item={item} />;
      default:
        return (<></>

        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
