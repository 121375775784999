import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "utils/axiosInstance"
import { addDaycare, delDayCareUrl, getByIdDayCare, getCentersUrl, getDayCareFilterUrl, updateDayCareUrl } from "utils/routesUrl"

const initialState = {
    dayCareData: [],
    dayCareFilterData: [],
    singleDayCareData: {},
    firstStepInfo:'',
    secondStepInfo:'',
    thirdStepInfo:'',
    loading: false,
    error: null,
    updateLoading: false,
    success: false
}

export const getDayCareCenter = createAsyncThunk(getCentersUrl, async (data) => {
    try {
        const response = await axiosInstance({
            url: `${getCentersUrl}`,
            method: 'POST',
            data: data
        })

        return response
    } catch (err) {
        return err
    }
})

export const getDayCareFilterList = createAsyncThunk(getDayCareFilterUrl, async (data) => {
    try {
        const response = await axiosInstance({
            url: `${getDayCareFilterUrl}`,
            method: 'POST',
            data: data
        })

        return response
    } catch (err) {
        return err
    }
})

export const addDaycareCenter = createAsyncThunk(addDaycare, async (data) => {
    try {
        const response = await axiosInstance({
            url: addDaycare,
            method: "POST",
            data
        })
        return response.data
    } catch (error) {
        return error.response
    }
})

export const dayCareGetById = createAsyncThunk(getByIdDayCare, async (data) => {
    try {
        const response = await axiosInstance({
            url: `${getByIdDayCare}?id=${data}`,
            method: 'GET'
        })
        return response.data
    } catch (err) {
        return err
    }
})

export const removeDayCare = createAsyncThunk(getByIdDayCare, async (data) => {

    try {
        const response = await axiosInstance({
            url: delDayCareUrl,
            method: "DELETE",
            data
        })
        response.data.id = data?.Id
        return response.data;
    } catch (error) {
        return error
    }
})

export const updateDayCare = createAsyncThunk(updateDayCareUrl, async (data, { rejectWithValue }) => {
    try {
        const response = await axiosInstance({
            url: updateDayCareUrl,
            method: "PUT",
            data
        })
        return response.data
    } catch (error) {
        console.log("error edit users", error)
        return rejectWithValue(error?.response)
    }
})


const dayCareSlice = createSlice({
    name: 'dayCare',
    initialState,
    reducers: {
        firstStep: (state, action) => {
            state.firstStepInfo = action.payload
        },
        secondStep: (state, action) => {
            state.secondStepInfo = action.payload
        },
        thirdStep: (state, action) => {
            state.thirdStepInfo = action.payload
        },
        clearStepData: (state) => {
            state = ''
            // state.singleDayCareData = {}
        }
    },
    extraReducers: {
        [getDayCareCenter.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getDayCareCenter.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.dayCareData = action.payload.data
        },
        [getDayCareCenter.rejected]: (state, action) => {
            state.loading = false
            state.success = false
        },
        [getDayCareFilterList.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [getDayCareFilterList.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.dayCareFilterData = action.payload.data
        },
        [getDayCareFilterList.rejected]: (state, action) => {
            state.loading = false
            state.success = false
        },
        [addDaycareCenter.pending]: (state) => {
            state.updateLoading = true
            state.error = null
        },
        [addDaycareCenter.fulfilled]: (state) => {
            state.updateLoading = false
            // state.firstStepInfo = ''
            // state.secondStepInfo = ''
            // state.thirdStepInfo = ''
        },
        [addDaycareCenter.rejected]: (state) => {
            state.updateLoading = false
            state.success = false
        },
        [dayCareGetById.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [dayCareGetById.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.singleDayCareData = action.payload
        },
        [dayCareGetById.rejected]: (state, action) => {
            state.loading = false
            state.success = false
        },
        [updateDayCare.pending]: (state) => {
            state.updateLoading = true
            state.error = null
        },
        [updateDayCare.fulfilled]: (state, action) => {
            state.success = true
            state.updateLoading = false
            state.firstStepInfo = ''
            state.secondStepInfo = ''
            state.thirdStepInfo = ''
        },
        [updateDayCare.rejected]: (state, action) => {
            state.updateLoading = false
            state.success = false
        },
    }
})

export const {firstStep, secondStep, thirdStep, clearStepData} = dayCareSlice.actions
export default dayCareSlice.reducer