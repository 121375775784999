// material-ui
import { Box } from '@mui/material';
import logo from '../assets/images/logo.png'
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return (
    <Box sx={{display: 'flex', alignItems:'center'}}>
        <img src={logo} style={{width: '200px'}} className='logo' />
    </Box>
  );
};

export default Logo;
