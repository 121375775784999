import {displaySuccessAlert} from "./displayAlert"

export { displaySuccessAlert };

// Config data
export const required = 'is required';
export const emailRequired = 'Email is required';
export const passwordRequired = 'Password is required'
export const validPhoneNumber = 'Enter valid Phone Number';
export const trim = 'Cannot include leading and trailing spaces';
export const validEmail = 'Enter a valid email';
export const minMsg = "Field requires between 3 and 30 characters."
export const minPassword = 'Password should contain atleast 5 digits';
export const passworsMsg = "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
export const BASE_URL = 'https://cutieestaging.azurewebsites.net/api/'
export const addedSucc = 'Added Successfully.'
export const updatedSucc = 'Updated Successfully.'
export const changePwd = 'Password Changed Successfully.' 
export const zipCodeMsg = "Invalid ZIP code."
export const coordinatesMsg = 'Invalid input. Please enter valid coordinates separated by commas.'
export const invalidAddMsg = 'Invalid Address'
export const addressMin = 'Field requires more than 5 characters'
export const ratingMin = 'should be minimun'
export const ratingMax = 'should be maximum'
export const fieldSelectMsg = 'Please select atleast one field'
export const googleClientId = '536102446112-qi688tue9n7nvm1plbbvbqd8g39t0d33.apps.googleusercontent.com'
export const API_KEY = 'AIzaSyCpWSb0knnOMffjRU4X-XK3X-Bk7HFmQeU'
export const invalidDate = 'Invalid Date'
export const dateFormat = 'DD/MM/YYYY'

// Regex data
export const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
export const coordintesRegex = /^(\d+(\.\d+)?(,\d+(\.\d+)?)*$)?$/