import React, { useState, useEffect } from 'react'
import { Grid, Box, Typography, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TabContext, TabList, TabPanel} from '@mui/lab';
import globalStylesCss from "globalStylesCss";
import Basic from './basic';
import Advanced from './advanced';
import DayCareSettings from './settings';
import { dayCareGetById, firstStep, secondStep, thirdStep } from 'store/slices/dayCareSlice';
import { get } from 'lodash';
import { CardBlocks, HelmetTitle, Loading } from 'views/components';

const AddCenter = () => {
    const classes = globalStylesCss();
    const dispatch = useDispatch()
    const params = useParams();
    const id = params?.id || '';
    const [value, setValue] = useState('1');
    const dayCareData = useSelector((state) => state.dateCareInfo)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() =>{
        if(id){
            dispatch(dayCareGetById(id))
        }
    }, [id])

    useEffect(() =>{
      if(id){
        if(get(dayCareData, 'singleDayCareData')){
            let basic = {
                name: get(dayCareData, 'singleDayCareData.name'),
                type: get(dayCareData, 'singleDayCareData.centerType'),
                mobNo: get(dayCareData, 'singleDayCareData.mobNo'),
                emailId: get(dayCareData, 'singleDayCareData.emailId'),
                address: get(dayCareData, 'singleDayCareData.address'),
                city: get(dayCareData, 'singleDayCareData.city'),
                state: get(dayCareData, 'singleDayCareData.state'),
                zip: get(dayCareData, 'singleDayCareData.zip'),
                country: get(dayCareData, 'singleDayCareData.country'),
                coordinates: get(dayCareData, 'singleDayCareData.latitude')+','+get(dayCareData, 'singleDayCareData.longitude')
            }
            let advanced = {
                ratings: get(dayCareData, 'singleDayCareData.rating'),
                about: get(dayCareData, 'singleDayCareData.about'),
                website: get(dayCareData, 'singleDayCareData.website'),
            }
            let settings = {
                ShowInDaycare: get(dayCareData, 'singleDayCareData.showInDaycare'),
                SyncInfo: get(dayCareData, 'singleDayCareData.syncInfo')
            }
        dispatch(firstStep(basic))
        dispatch(secondStep(advanced))
        dispatch(thirdStep(settings))
    }
      } else {
        dispatch(firstStep({}))
        dispatch(secondStep({}))
        dispatch(thirdStep({}))
      }
}, [id, get(dayCareData, 'singleDayCareData')])

  return (
    <>
        <HelmetTitle title='Centers' />
        <Grid container className={`${classes.secondmainrow} secondmainrow`} >
            <Grid item xs={12} sm={10} md={10} lg={8} xl={5} className='form-container'>
                {get(dayCareData, 'loading') && dayCareData.singleDayCareData  ? <Loading /> : 
                <>
                <Grid item xs={6} mb='22px' >
                    <Typography variant="h2" >{id ? 'Edit Daycare' : 'New Daycare'}</Typography>
                </Grid>
                <CardBlocks className="adduser-card" title="Daycare" nomargin >
                    <Box sx={{ width: '100%' }} >
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: '#EFEFEF' }}>
                                <TabList className='tabs-wrapper'>
                                    <Tab label="1: Basic" className={classes.tabListStyle} value="1" />
                                    <Tab label='2: Advanced' className={classes.tabListStyle} value="2" />
                                    <Tab label="3: Settings" className={classes.tabListStyle} value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Basic handleStepChange={handleChange} classes={classes} dispatch={dispatch}  params={params}/>
                            </TabPanel>
                            <TabPanel value="2">
                                <Advanced handleStepChange={handleChange} classes={classes} dispatch={dispatch} params={params}/>
                            </TabPanel>
                            <TabPanel value="3">
                                <DayCareSettings handleStepChange={handleChange} classes={classes} dispatch={dispatch} params={params}/>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </CardBlocks>
                </>
                    } 
            </Grid>
        </Grid>
    </>
  )
}

export default AddCenter