import dashboard from './dashboard';
import users from './users';
import activity from './activity';
import settings from './settings'
import center from './center';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, users, activity, center, settings]
};

export default menuItems;
