import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { makeStyles } from "@mui/styles"
const SelectComponent = ({
    label,
    onSelecteChange,
    items,
    name,
    value,
    labelError,
    helperText,
    ...rest
}) => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const classes = useStyles()
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel ref={inputLabel} id="demo-simple-select-autowidth-label" className={!labelError ? classes.label : classes.labelError}>
                {label}
            </InputLabel>
            <Select
                label={label}
                labelId={`select-${label}`}
                name={name}
                onChange={onSelecteChange}
                labelWidth={labelWidth}
                value={value}
                multiple={false}
                {...rest}
                className={classes.selectBorder}
            >
                {items.length > 0
                    ? items.map((item, i) => (
                        <MenuItem value={item.value} key={i}>
                            {item.name}
                        </MenuItem>
                    ))
                    : null}
            </Select>
            <FormHelperText
                sx={{ color: labelError && 'error.main' }}
            >
                {helperText}
            </FormHelperText>
        </FormControl>
    );
};

export default SelectComponent;
const useStyles = makeStyles((theme) => ({
    selectBorder: {
        '&.MuiInputLabel-root': {
            color: theme.palette.secondary.main,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.secondary.main
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
        },
    },
    label: {
        color: theme.palette.secondary.dark,
        "&.Mui-focused": {
            color: theme.palette.secondary.dark,
        },
    },
}));