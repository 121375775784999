import { makeStyles } from "@mui/styles";




const globalStylesCss = makeStyles((theme) => ({

    addbtnlink: {
        marginTop: '5px'
    },
    addUserBtn: {
        color: "#fff",
    },
    secondmainrow: {
        display: 'flex',
        paddingTop: '20px',
        "&&": {
            // padding: theme.spacing(4),
            [theme.breakpoints.down("xs")]: {
                marginTop: 75,
                padding: theme.spacing(2),
            },
        },
    },
    badge: {
        marginLeft: "0px",
        ml: '60px', "& .MuiBadge-badge": { width: "70px", fontSize: 12, padding: "10px", minWidth: 15, borderRadius: '5px', height: "25px",}
    },
    container: {
        maxHeight: 600,
    },
    linkTextDecoration: {
        textDecoration: "none",
    },
    feautedImageBoxPreview: {
        maxWidth: "90%",
        maxHeight: "90%",
    },
    width100: {
        width: "100%",
    },
    feautedImage: {
        display: "flex",
        cursor: "pointer",
        fontSize: "15px",

    },
    feautedImageBox: {
        background: "rgb(240,240,240)",
        height: "250px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: theme.spacing(2),
    },
    feautedImageContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    updateUserBtnContainer: {
        display: 'flex',
        justifyContent: 'end',

    },
    deleteBtn: {
        color: '#ff5f5f'
    },
    BtnContainer: {
        display: 'flex',
        justifyContent: 'end',
        marginLeft: '10px',
        // paddingRight: theme.spacing(3)
    },
    btnColor: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.dark,
        borderRadius: '30px',
        padding: '8px 18px !important',
        fontWeight: '400',
        textTransform: 'none'
    },
    userBtnColor: {
        backgroundColor: `${theme.palette.secondary.dark} !important`,
        borderRadius: '30px',
        color: theme.palette.primary.dark,
        fontWeight: '400',
        textTransform: 'none',
        fontSize: '17px',
        padding: '6px 22px'
    },
    cancelbtn: {
        color: '#b2b2b2',
        borderRadius: '30px',
        fontWeight: '400'
    },
    tabListStyle: {
        minHeight: '28px', 
        color: '#bf5af2',
        marginRight: '40px',
        fontWeight: '300',
        fontSize: '15px'
    },
    labelColor: {
        color: '#673ab7',
        '& label.Mui-focused': {
            color: 'green',
        },
        '& label.Mui-root': {
            color: 'blue',
        },
    },
    labelErrorColor: {
        color: 'red'
    },
    logoLink: {
        textDecoration: 'none'
    },
    loaderWrapper: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        top: '40%',
        left: '48%',
        transform: 'translateX(-50%)',
        color: theme.palette.secondary.dark,
        '& .MuiCircularProgress-root ': {
            marginBottom: '12px'
        }
    },
    userDelBtn: {
        color: '#f25a5b',
        backgroundColor: '#fff2f2',
        height: '40px',
        width: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    cutieeProfile: {
        height: 23,
        width: 23,
        borderRadius: '50%',
        color: theme.palette.secondary.dark
    },
    columnFilterIcon: {
        marginLeft: '20px', 
        cursor: 'pointer'
    },
    mainLoadingStyle: {
        color: '#bf5af2',
        marginBottom: '10px'
    },
    delIconStyle: {
        height: '21px',
        color: '#f25a5b',
        width: '18px'
    },
    addAdminLabel: {
        display: 'flex',
        justifyContent: 'space-around',
        textAlign: 'left',
        width: '90%'
    },
    adminEmailMsg: {
        color: 'red',
        fontSize: '12px',
        lineHeight: 'normal',
        marginTop: '8px !important'
    },
    addAdminForm: {
        display: 'flex',
        alignItems: 'flex-start', 
        justifyContent: 'space-between', 
        marginBottom: '10px'
    },
    datePickerStyle: {
        '& label': {
            color: theme.palette.secondary.dark,
        },
        '& label.Mui-focused': {
            color: 'red',
            "& $notchedOutline": {
                borderColor: theme.palette.input.light,
                borderRadius: '0px',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& Input': {
                height: '12px',
            },
            '& fieldset': {
                borderColor: theme.palette.secondary.main,
                borderRadius: '0px',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
            },
        },
    },
    accourdionSummayBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    accourdingDetails: {
        padding: 0,
        transition: '1ms ease-out',
        transitionDelay: '0.1ms',
    },
    addCutieeForm: {
        marginTop: '16px', 
        borderBottom: '1px solid #EFEFEF', 
        paddingBottom: '24px',
        justifyContent: 'space-between !important'
    },
    addCutieeFormGrid: {
        display: 'flex', 
        alignItems: 'center', 
        marginBottom: '16px', 
        justifyContent: 'space-between !important'
    },
    cutieeGridIcon: {
        marginTop: '24px', 
        borderBottom: '1px solid #EFEFEF',
        paddingBottom: '24px'
    },
    addCutieeGridIcon: {
        display: 'flex', 
        alignItems: 'center', 
        marginBottom: '18px'
    },
    cutieeDayCareIcon: {
        height: '20px',
        width: '18px', 
        color: '#2a886b'
    },
    cutieeDayCareTypo: {
        marginLeft: '16px', 
        color: '#2a886b'
    },
    dropOffGrid: {
        width: '50%',
        display: 'flex',
        justifyContent: 'space-between !important'
    },
    dropOffStack: {
        width: '100%', 
        marginRight: '15px'
    },
    weeklyScheduleStack: {
        backgroundColor: '#F5F5F5',
        borderRadius: '30px',
        padding: '5px',
        marginTop: '5px', 
        justifyContent: 'space-between'
    },
    weeklyScheduleBox: {
        height: '28px',
        cursor: 'pointer',
        width: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%'
    },
    trustedContactBox: {
        display: 'flex', 
        alignItems: 'center', 
        marginTop: '5px',
        border: '1px solid #dedede',
        borderRadius: '5px'
    },
    trustedContactMsg: {
        color: theme.palette.error.main, 
        fontSize: '12px'
    },
    truestedContactIcon: {
        height: '20px',
        width: '18px', 
        color: theme.palette.error.main
    },
    trustedContactHeading: {
        marginLeft: '16px', 
        color: theme.palette.error.main
    },
    contactEmergencyBox: {
        display: 'flex', 
        alignItems: 'center', 
        marginTop: '5px',
        border: '1px solid #dedede',
        borderRadius: '5px'
    },
    addAdminErrMsg: {
        color: theme.palette.error.main, 
        marginTop: '4px', 
        fontSize: '12px'
    },
    adminAddInputBox: {
        display: 'flex', 
        flexDirection: 'column',
        width: '100%'
    },
    adminAddPwdInputBox: {
        display: 'flex',
        flexDirection: 'column',
        // width: '49%'
    },
    socialLoginBtn: {
        backgroundColor: '#fff !important',
        color: '#000',
        borderRadius: '20px',
        boxShadow: 'unset',
        border: '1px solid #ccc',
        fontWeight: '300',
        textTransform: 'none',
        fontSize: '17px',
        padding: '4px 22px !important'
    },
    cutieeNameStyle: {
        marginLeft: '16px',
        color: '#999999',
        textTransform: 'capitalize'
    },
    forgotBackStyle: {
        color: theme.palette.secondary.dark,
        cursor: 'pointer !important',
        fontWeight: '400 !important',
        fontSize: '12px !important',
        textDecoration: 'underline',
    },
    settingBox: {
        marginBottom: '15px',
        paddingBottom: '15px', 
        borderBottom: '1px solid #EFEFEF',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}))
export default globalStylesCss