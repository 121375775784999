// assets
import centerIcon from '../assets/images/icons/centers.svg';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const center = {
  id: 'center',
  type: 'group',
  children: [
    {
      id: 'centers',
      type: 'item',
      url: '/centers',
      role: [1, 2, 3],
      icon: centerIcon,
      title: 'Centers',
      breadcrumbs: false
    },
  ]
};

export default center;
