import {useState} from 'react'
import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import Auth from 'utils/auth';
import logoutIcon from '../../../../assets/images/icons/logout.svg';
import SvgIcons from 'views/components/svgIcons';

// ==============================|| PROFILE MENU ||============================== //

const LogoutButton = ({drawerToggle}) => {
    const [windowWidth, setWindowWidth] = useState(getCurrentDimension);

  function getCurrentDimension(){
    return window.innerWidth
  }
    const handleLogout = async () => {
        Auth.logout();
        if(windowWidth <= 1024){
            drawerToggle()
        }
    };
    return (
        <>
            <Box >
                <List
                    component="nav"
                    sx={{
                        width: '100%',
                        padding: 0,
                        marginTop: '5px'
                    }}
                    className='logout-btn'
                >
                    <ListItemButton
                        sx={{ padding: '10px 0' }}
                        onClick={handleLogout}
                    >
                        <ListItemIcon sx={{ mr: '6px' }}>
                            <SvgIcons src={logoutIcon} style={{ color: '#ff5f5f', height: '18px' }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="navLink" color='#ff5f5f' sx={{fontWeight: '400'}}>Log out</Typography>} />
                    </ListItemButton>
                </List>
            </Box>
        </>
    );
};

export default LogoutButton;
