
import { configureStore } from '@reduxjs/toolkit';
// reducer import
import customizationReducer from './customizationReducer';
import adminLogin from 'store/slices/loginSlice';
import usersInfo from 'store/slices/userSlice';
import alertSlice from 'store/slices/alertSlice';
import activitySlice from 'store/slices/activitySlice';
import cutieeSlice from 'store/slices/cutieeSlice';
import settingSlice from 'store/slices/settingSlice';

import dayCareReducer from 'store/slices/dayCareSlice';
import userAnalytics from 'store/slices/UserAnalyticsSlice';
import removeDaycareSlice from 'store/slices/removeDaycareSlice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = configureStore({
  reducer: {
    customization: customizationReducer,
    adminLogin: adminLogin,
    usersInfo: usersInfo,
    activityInfo: activitySlice,
    alertSlice: alertSlice,
    cutieeInfo: cutieeSlice,
    adminSlice: settingSlice,
    dateCareInfo: dayCareReducer,
    userAnalyticsSlice : userAnalytics,
    removeDaycareInfo: removeDaycareSlice
  }
});

export default reducer;
