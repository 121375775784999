import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/forgotPassword/resetPassword')));
const ForgetPassword = Loadable(lazy(() => import('views/pages/authentication/forgotPassword/forgotPassword')))
// login option 3 routing
const Login = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  // element: <Login />,
  children: [
    {
      path: '/',
      element: <Login />
    },
    {
      path: '/forgetPassword',
      element: <ForgetPassword />
    },
    {
      path: '/resetPassword',
      element: <ResetPassword />
    },
    {
      path: '*',
      element: <Login />
    },
  ]
};

export default AuthenticationRoutes;
