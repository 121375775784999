import { useState, useEffect } from 'react';
import { Grid, Box, Typography, AccordionSummary, Accordion, Stack, AccordionDetails } from "@mui/material";
import { removeCutiee, updateCutiee } from "store/slices/cutieeSlice";
import { ActionButton, DatePickerComponent, Loading, PhoneInput, SvgIcons, TextInput } from 'views/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Formik } from 'formik';
import { get } from 'lodash';
import { AddCutieeValidateSchema } from 'utils/validation';
import activityIcon from '../../../assets/images/icons/delete.svg';
import trustedIcon from '../../../assets/images/icons/trusted.svg';
import daycareIcon from '../../../assets/images/icons/daycare.svg';
import userIcon from '../../../assets/images/icons/user-profile.svg';
import ConfirmationModel from 'views/confirmationModel/confirmationModel';
import { dateFormat, displaySuccessAlert, invalidDate, updatedSucc } from 'utils';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Cutiees = ({ userData, params, classes, dispatch, theme, handleRefreshApi }) => {
    const id = params?.id || ''
    const [open, setOpen] = useState(false);
    const [cutieeId, setCutieeId] = useState('')
    const [cutieesData, setCutieesData] = useState([])
    const [weeklySchedule, setWeeklySchedule] = useState([])
    const [isShowLoader, isSetShowLoader ] = useState()
    const cutieeDataSlice = useSelector((state) => state.cutieeInfo)
    useEffect(() => {
        if (id) {
            setCutieesData(get(userData, 'singleUserData.cutiees'))
        }
    }, [id, userData])

    useEffect(() => {
        const targetArray = [];
        if (cutieesData && cutieesData?.length > 0) {
          for (let i = 0; i < cutieesData?.length; i++) {
            targetArray.push([
                { day: 'S', active: false, value: 'sunday' },
                { day: 'M', active: false, value: 'monday' },
                { day: 'T', active: false, value: 'tuesday' },
                { day: 'W', active: false, value: 'wednesday' },
                { day: 'T', active: false, value: 'thursday' },
                { day: 'F', active: false, value: 'friday' },
                { day: 'S', active: false, value: 'saturday' }
            ]);
          }
          cutieesData.map((res, index) => {
            let dyData = cutieesData[index]?.cutieeDaycares[0]?.days;
            const innerArray = targetArray[index];
            for (let j = 0; j < innerArray.length; j++) {
                const dayObj = innerArray[j];
                const dayName = dayObj.value.toLowerCase();
              if (dyData?.hasOwnProperty(dayName)) {
                targetArray[index][j].active = dyData[dayName];
              }
            }
            setWeeklySchedule(targetArray);
          });
        }
      }, [userData, cutieesData])

    const addSchedules = (data, i) => {
        data.active=!data.active
        setWeeklySchedule([...weeklySchedule])
      
    }

    const deleteCutiees = (e, i) => {
        e.stopPropagation()
        // setUserId(i)
        setCutieeId(i)
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const deleteUser = async () => {
        setOpen(false);
        id && cutieeId && isSetShowLoader(true)
        // let data = {
        //     Id: userId
        // }
        let data = {
            userId : id,
            cutieeId
        }
        id && cutieeId && await dispatch(removeCutiee(data)).then((res) => {
            if (res?.payload?.success) {
                let filterData = cutieesData?.filter(res => res?.id !== cutieeId)
                 setCutieesData(filterData)
                // isSetShowLoader(false)
                handleRefreshApi()
                displaySuccessAlert(dispatch, 'success', get(res, 'payload.message'))
            } else {
                displaySuccessAlert(dispatch, 'error', get(res, 'payload.message'))
            }
        })
        // .finally(()=> isSetShowLoader(false))
    };

    const handleTrustedContact = (values) =>{
        const trustedContacts = values.trustedContactsName.map((name, index) => ({
            name: name,
            no: values.trustedContactsNo[index],
        }));
        return trustedContacts
    }

    const handleEmergencyContact = (values) =>{
        const emergencyContacts = values.emergencyContactsName.map((name, index) => ({
            name: name,
            no: values.emergencyContactsNo[index],
        }));
        return emergencyContacts
    }

    const submitDataDaycare = (values, cutieeData, indexCutiee) =>{
        const daydata = {};
        const filteredArray = weeklySchedule[indexCutiee]
        filteredArray.map((item) => {
            if (item.active)
                daydata[item.value] = true;
        });
        let data = [{
            additionalInfo: values.additionalInfo,
            alertAfter: values.alertAfter,
            daycare: null,
            daycareId: cutieeData.cutieeDaycares[0].daycareId,
            dropOffTime: moment(values?.dropOffTime, "hh:mm:ss").format("HH:mm:ss"),
            ...daydata
        }]

        return data
    }
    
    const handleSubmitCutiee = (values, cutieeData, indexCutiee) =>{
        let currentDate = moment(new Date).format(dateFormat) 
        let date = moment(new Date(values.dob)).format(dateFormat) 
        if(date > currentDate){
            displaySuccessAlert(dispatch, 'error', invalidDate)
            return;
        }

        let data = {
            id: cutieeData?.id,
            CutieeImage: cutieeData?.image,
            firstName: values?.firstName,
            lastName: values?.lastName,
            dob: values?.dob,
            mainParentId: cutieeData.mainParentId,
            cutieeDaycares: submitDataDaycare(values, cutieeData, indexCutiee),
            trustedContacts: handleTrustedContact(values),
            emergencyContacts: handleEmergencyContact(values),
        }
        dispatch(updateCutiee(data)).then((res) => {
            if (get(res, "payload.success")) {
                const newObj = cutieesData?.map((res) => {
                    if (values.id == res.id) {
                        return values
                    }
                    return res
                })
                setCutieesData(newObj)
                handleRefreshApi()
                displaySuccessAlert(dispatch, 'success', `Cutiee ${updatedSucc}`)
            } else {
                displaySuccessAlert(dispatch, 'error', get(res, 'payload.data.message') || get(res, 'payload.data.title'))
            }
        })
    }

    // useEffect(() =>{
    //     // if(id){
    //         if(get(userData, 'singleUserData.cutiees')?.length){
    //             isSetShowLoader(false)
    //         } else {
    //             isSetShowLoader(true)
    //         }
    //     // }
    // }, [userData])

    useEffect(() =>{
       if(userData.loading){
        isSetShowLoader(true)
       }else{
        isSetShowLoader(false)
       }
    }, [userData])
    return (
        <Box>
             {!isShowLoader && cutieesData?.length >= 0 ? cutieesData.map((item, i) => {
                return (
                    <Accordion
                        sx={{ marginBottom: '0px !important', border: 'none' }}
                        key={i}
                        className='cuties-accordion'
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon
                                sx={{ color: '#ccc !important1', ml: 1, transform: 'rotate(-90deg)' }} />}
                            aria-controls="supporting-courses"
                            id={i}
                            sx={{ borderBottom: '1px solid #EFEFEF', padding: '0px' }}
                        >
                            <Box className={`accordion-head ${classes.accourdionSummayBox}`}>
                                <Stack direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                                    {item?.image ? <img src={`data:image/png;base64,${item?.image}`} className='cutiee-profle' alt="profile" />
                                        : <SvgIcons src={userIcon} className={classes.cutieeProfile} />}
                                    <Typography variant='subtitle1' className={classes.cutieeNameStyle} >{item?.firstName} {item?.lastName}</Typography>
                                </Stack>
                                <Stack sx={{ p: 0, mr: 1 }} className='controls'>
                                    <Box className={`${classes.userDelBtn} delete-btn`} onClick={(e) => deleteCutiees(e, item?.id)}>
                                        <SvgIcons src={activityIcon} className={classes.delIconStyle} />
                                    </Box>
                                </Stack>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accourdingDetails}>
                            <Formik
                                initialValues={{
                                    firstName: cutieesData[i].firstName,
                                    lastName: cutieesData[i].lastName,
                                    dob: cutieesData[i].dob,
                                    name: cutieesData[i].cutieeDaycares[0]?.name,
                                    additionalInfo: cutieesData[i].cutieeDaycares[0]?.additionalInfo,
                                    dropOffTime: moment(cutieesData[i].cutieeDaycares[0]?.dropOffTime, "HH:mm:ss").format("hh:mm:ss A"),
                                    alertAfter: cutieesData[i].cutieeDaycares[0]?.alertAfter,
                                    trustedContactsName: cutieesData[i]?.trustedContacts.map((res) => res.name),
                                    trustedContactsNo: cutieesData[i]?.trustedContacts.map((res) => res.no),
                                    emergencyContactsName: cutieesData[i]?.emergencyContacts.map((res) => res.name),
                                    emergencyContactsNo: cutieesData[i]?.emergencyContacts.map((res) => res.no),
                                }}
                                validationSchema={AddCutieeValidateSchema}
                                enableReinitialize={true}
                                onSubmit={async (values, { }) => {
                                    handleSubmitCutiee(values, cutieesData[i], i)
                                }}
                                >
                                {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, setValues, isSubmitting, touched, values }) => (
                                    <>
                                        <form noValidate onSubmit={handleSubmit} className='cutiees-form'>
                                            <Grid container  className={`adduser-form ${classes.addCutieeForm}`}>
                                                <Grid item md={12} xs={12} className={` cutiees-header ${classes.addCutieeFormGrid}`}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                       <SvgIcons src={userIcon} className={classes.cutieeProfile} />
                                                        <Typography variant='subtitle1' sx={{ ml: 2, color: theme.palette.secondary.dark, fontSize: '21px' }}>Name</Typography>

                                                    </Box>
                                                    <ActionButton color='secondary' disabled={isSubmitting} cancel={false} title='Save changes' isLoading={cutieeDataSlice?.loading} fullWidth size="large" type="submit" variant="contained" />
                                                </Grid>

                                                <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                                    <Box component="div"  >
                                                        <TextInput
                                                            type="text"
                                                            value={values?.firstName}
                                                            name="firstName"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="First name"
                                                            error={touched.firstName && Boolean(errors.firstName)}
                                                            helperText={touched.firstName && errors.firstName}
                                                        />
                                                    </Box>
                                                    <Box component="div" >
                                                        <TextInput
                                                            type="text"
                                                            value={values?.lastName}
                                                            name="lastName"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Last name"
                                                            error={touched.lastName && Boolean(errors.lastName)}
                                                            helperText={touched.lastName && errors.lastName}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                                    <Box component="div">
                                                        <DatePickerComponent
                                                            value={values?.dob}
                                                            name="dob"
                                                            onChange={(val) => setValues({ ...values, dob: val })}
                                                            date={new Date(values?.dob ? values?.dob : '')}
                                                            width="100%"
                                                            error={touched.dob && Boolean(errors.dob)}
                                                            helperText={touched.dob && errors.dob}
                                                        />
                                                    </Box>
                                                    
                                                </Grid>
                                            </Grid>
                                            <Grid container className={`adduser-form ${classes.cutieeGridIcon}`}>
                                                <Grid item md={12} xs={12} className={` cutiees-header ${classes.addCutieeGridIcon}`}>
                                                    <SvgIcons src={daycareIcon} className={classes.cutieeDayCareIcon} />
                                                    <Typography variant='subtitle1' fontSize={21} className={classes.cutieeDayCareTypo}>Daycare</Typography>
                                                </Grid>

                                                <Grid item md={12} xs={12}>
                                                    <Box component="div"  >
                                                        <TextInput
                                                            type="text"
                                                            name="name"
                                                            disabled={true}
                                                            value={values.name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Daycare center"
                                                            error={touched.name && Boolean(errors.name)}
                                                            helperText={touched.name && errors.name}
                                                        />
                                                    </Box>
                                                    <Box component="div">
                                                        <TextInput
                                                            type="text"
                                                            name='additionalInfo'
                                                            value={values.additionalInfo}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Additional notes"
                                                            error={touched.additionalInfo && Boolean(errors.additionalInfo)}
                                                            helperText={touched.additionalInfo && errors.additionalInfo}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item md={12} gap='2' xs={12} className={`${classes.dropOffGrid} dropoff-grid`}>
                                                    <Stack direction='row' className={`${classes.dropOffStack} drop-off`}>
                                                        <Box component="div" sx={{ mr: 3, width: '45%' }}>
                                                            <TextInput
                                                                type="text"
                                                                name='dropOffTime'
                                                                value={values.dropOffTime}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                label="Dropoff time"
                                                                error={touched.dropOffTime && Boolean(errors.dropOffTime)}
                                                                helperText={touched.dropOffTime && errors.dropOffTime}
                                                            />
                                                        </Box>
                                                        <Box component="div" sx={{ width: '45%' }}>
                                                            <TextInput
                                                                type="number"
                                                                name='alertAfter'
                                                                value={values.alertAfter}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                min='0'
                                                                max='30'
                                                                label="Alert after"
                                                                error={touched.alertAfter && Boolean(errors.alertAfter)}
                                                                helperText={touched.alertAfter && errors.alertAfter}
                                                            />
                                                        </Box>
                                                    </Stack>
                                                    <Stack direction='row' sx={{ width: '100%' }} className='schedule'>
                                                        <Box component="div" sx={{ width: '100%' }}>
                                                            <Typography color='#000000' fontSize={12} >Weekly schedule</Typography>
                                                            <Box>
                                                                <Stack direction='row' className={classes.weeklyScheduleStack}>
                                                                    {weeklySchedule && weeklySchedule.map((dataB, iw) => (
                                                                        i===iw?
                                                                        dataB.map((item, index) =>(
                                                                            <Box onClick={() => addSchedules(item, index)} sx={{ backgroundColor: `${item.active ? "#4B4B4B" : "#F5F5F5"}`,}} className={classes.weeklyScheduleBox}
                                                                        >
                                                                            <Typography sx={{ color: `${item.active ? "white" : "#999999"}` }}>{item.day}</Typography>
                                                                        </Box>
                                                                        )):null
                                                                    ))}
                                                                </Stack>
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Grid container className={`adduser-form trusted-contacts ${classes.cutieeGridIcon}`}>
                                                <Grid item md={12} xs={12} className={` cutiees-header ${classes.addCutieeGridIcon}`}>
                                                    <SvgIcons src={trustedIcon} className={classes.truestedContactIcon} />
                                                    <Typography variant='subtitle1' className={classes.trustedContactHeading} fontSize={21}>Trusted Contacts</Typography>
                                                </Grid>

                                                <Grid container justifyContent='space-between' >
                                                    {values?.trustedContactsName?.map((item, index) => (
                                                        <Grid item xs={12} className='two-fields-wrapper'>
                                                            <Box>    
                                                            <label>{`#${index + 1}`}</label>
                                                            <Box component="div" className={classes.trustedContactBox} >
                                                                <Box sx={{flexBasis: '40%'}}>
                                                                <TextInput
                                                                    type="text"
                                                                    value={item}
                                                                    name='name'
                                                                    onBlur={handleBlur}
                                                                    onChange={text => {
                                                                        const newEmergencyNumbers = [...values.trustedContactsName];
                                                                        newEmergencyNumbers[index] = text.target.value;
                                                                        setFieldValue('trustedContactsName', newEmergencyNumbers);
                                                                    }}
                                                                />
                                                                </Box>
                                                                <PhoneInput
                                                                    type="text"
                                                                    value={values.trustedContactsNo[index]}
                                                                    name="no"
                                                                    onBlur={handleBlur}
                                                                    defaultCountry="US"
                                                                    onChange={text => {
                                                                        const newEmergencyNumbers = [...values.trustedContactsNo];
                                                                        newEmergencyNumbers[index] = text
                                                                        setFieldValue('trustedContactsNo', newEmergencyNumbers);
                                                                    }}
                                                                    error={touched.mobNo && Boolean(errors.mobNo)}
                                                                    helperText={touched.mobNo && errors.mobNo}
                                                                />
                                                                
                                                            </Box>
                                                            {errors.trustedContactsName &&
                                                                errors.trustedContactsName[index] &&
                                                                touched.trustedContactsName ? (
                                                                    <Grid className={classes.trustedContactMsg}>
                                                                    {errors.trustedContactsName[index]}
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid> </Grid>
                                                                )}
                                                                {errors.trustedContactsNo &&
                                                                errors.trustedContactsNo[index] &&
                                                                touched.trustedContactsNo ? (
                                                                    <Grid className={classes.trustedContactMsg}>
                                                                    {errors.trustedContactsNo[index]}
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid> </Grid>
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                <Grid container justifyContent='space-between'>

                                                    {values?.emergencyContactsName?.map((item, index) => (
                                                        <Grid item xs={12} className=' two-fields-wrapper'>
                                                            <label>Emergency {`#${index + 1}`}</label>
                                                            <Box component="div" className={classes.contactEmergencyBox} >
                                                            <Box sx={{flexBasis: '40%'}}>
                                                                <TextInput
                                                                    type="text"
                                                                    value={item}
                                                                    name='name'
                                                                    onBlur={handleBlur}
                                                                    onChange={text => {
                                                                        const newEmergencyNumbers = [...values.emergencyContactsName];
                                                                        newEmergencyNumbers[index] = text.target.value;
                                                                        setFieldValue('emergencyContactsName', newEmergencyNumbers);
                                                                    }}
                                                                />
                                                            </Box>
                                                                <PhoneInput
                                                                    type="text"
                                                                    value={values.emergencyContactsNo[index]}
                                                                    name="no"
                                                                    onBlur={handleBlur}
                                                                    defaultCountry="US"
                                                                    onChange={text => {
                                                                        const newEmergencyNumbers = [...values.emergencyContactsNo];
                                                                        newEmergencyNumbers[index] = text
                                                                        setFieldValue('emergencyContactsNo', newEmergencyNumbers);
                                                                    }}

                                                                    error={touched.mobNo && Boolean(errors.mobNo)}
                                                                    helperText={touched.mobNo && errors.mobNo}
                                                                />
                                                                
                                                            </Box>
                                                            {errors.emergencyContactsName &&
                                                                errors.emergencyContactsName[index] &&
                                                                touched.emergencyContactsName ? (
                                                                    <Grid className={classes.trustedContactMsg}>
                                                                    {errors.emergencyContactsName[index]}
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid> </Grid>
                                                                )}
                                                                {errors.emergencyContactsNo &&
                                                                errors.emergencyContactsNo[index] &&
                                                                touched.emergencyContactsNo ? (
                                                                    <Grid className={classes.trustedContactMsg}>
                                                                    {errors.emergencyContactsNo[index]}
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid> </Grid>
                                                                )}
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </>
                                )}
                            </Formik>
                        </AccordionDetails>
                    </Accordion>
                )
            }) :''}
        {/* {(isShowLoader || cutieesData?.length <= 0) && id  && ( */}
        {isShowLoader && id  && (
            <Box sx={{position: 'relative', display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
                <Loading />
            </Box>
        )}
        {/* {isShowLoader  && cutieesData?.length == 0 && id?.length == 3 || id?.length == 0 && (
           <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
               No Cutiees Available
           </Grid>
        )} */}
        {!isShowLoader  && cutieesData?.length == 0 && (id?.length == 3 || id?.length == 0) && (
           <Box sx={{ display: 'flex', justifyContent: 'center',alignItems:'center', height:'100%' }}>
                <Typography >
                   No Cutiees Available
                </Typography>
           </Box>
        )}
        {/* {isShowLoader && cutieesData?.length == 0 && id?.length == 3 && (
           <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
               No Cutiees Available
           </Grid>
        )} */}
           
            {open ? <ConfirmationModel title={'Cutiee'} open={open} handleClose={handleClose} deleteUser={deleteUser} /> : null}
        </Box>
    )
}


export default Cutiees;