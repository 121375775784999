import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Chip, CssBaseline, Divider, Drawer, Stack, Typography, useMediaQuery } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'store/constant';
import LogoutButton from './logout/logout';
import ProfileSection from './profileSection/profileSection';
import SvgIcons from 'views/components/svgIcons';
import  logo  from '../../../assets/images/logo.png'

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window, windowWidth, className }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const drawer = (
    <>
      {/* <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box> */}

      {/* <BrowserView>  */}
        {/* <Box>
            <LogoSection />
          </Box> */}
          <Box className="sidebar-logo">
            {/* <SvgIcons src={logo}/> */}
            {/* <Typography sx={{ color: '#000', fontWeight: '700',fontSize: '38px', marginRight: '8px'}} >Cutiee </Typography>  */}
            <img src={logo} className='logo' />
            {
              drawerOpen && windowWidth <= 1024 && 
              <Button className='menu-close-btn menu-btn' onClick={drawerToggle}><ChevronLeftRoundedIcon/></Button>
            }
          </Box>
            <PerfectScrollbar component="div">
            <Box className="sidebar-links">
              <Box className="links">
                <MenuList drawerToggle={drawerToggle}/>
              </Box>
              <LogoutButton drawerToggle={drawerToggle}/>
            </Box>
          
            {/* <Divider /> */}
          <Stack className='sidebar-action'>
            {/* <ProfileSection /> */}
            {/* <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} /> */}
          </Stack>
        </PerfectScrollbar>
      {/* </BrowserView> */}
      {/* <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />

          <Stack sx={{ position: 'absolute', bottom: 0 }}>
            <ProfileSection />
            <LogoutButton />
            <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
          </Stack> */}
          {/* <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
            <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
          </Stack> */}
        {/* </Box>
      </MobileView> */}

    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" className={className} aria-label="mailbox folders">
      {
        !drawerOpen &&
        <Button className='menu-btn' onClick={drawerToggle}><MenuRoundedIcon/></Button>
      }
      <Drawer
        container={container}
        variant={'persistent'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        className='app-drawer'
        sx={{
          '& .MuiDrawer-paper': {
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
