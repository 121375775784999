import axios from "axios";
import Cookies from 'universal-cookie';
import Swal from "sweetalert2";
import { displaySuccessAlert } from "./displayAlert";
import { API_KEY, invalidAddMsg } from "./index";

const cookies = new Cookies()
const stagingUrl = 'https://cutieestaging.azurewebsites.net/api/'

const axiosInstance = axios.create({
    baseURL: stagingUrl,
    // baseURL: '/api/',
    headers: {
         Authorization: `<Auth Token>`,
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use((config) => {
    const jwtToken = cookies.get("token")
    const token = jwtToken || ""

    // checking if accessToken exists
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


axiosInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        console.log("errpr in 401")
        cookies.remove("token", { path: '/' })
        Swal.fire({
            title: 'Session Expired!',
            text: 'Please Login Again',
            icon: 'error',
            confirmButtonText: 'Log in'
        }).then(() => {
            window.location.pathname = '/';
        })
        return Promise.reject(error);
    }
    return Promise.reject(error);

});

export const handleGeocode = async (values, dispatch) => {
    let address = values.address + ',' + values.city + ',' + values.zip + ','+values.state
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`;
    try {
      const response = await fetch(`${url}`);
      const data = await response.json();
      const result = data.results[0];
      if (result) {
        const {lat, lng} = result.geometry.location;
        return {latitude: lat, longitude: lng};
      } else {
        displaySuccessAlert(dispatch, 'error', invalidAddMsg)
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
      return null;
    }
  };

export default axiosInstance;