import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "utils/axiosInstance"
import { getActivityUrl } from "utils/routesUrl"

const initialState = {
    activityData: [],
    loading: false,
    error: null,
    success: false
}
export const activityInfo = createAsyncThunk(getActivityUrl, async () => {
    try {
        const response = await axiosInstance({
            url: getActivityUrl,
            method: 'GET',
            // data: data
        })

        // const res = [
        //     {
        //         id: 374,
        //         image: "",
        //         firstName: "Mary",
        //         lastName: "Line",
        //         centerName: "Sai ram plaza ",
        //         city: "Indore",
        //         state: "MP",
        //         emailId: "magne@mailinator.com",
        //         dropOffTime: "12:43:00",
        //         lastUpdated: "2023-09-19T07:12:01.6513353",
        //         activityStatus: 3,
        //         status: {
        //             text: "System",
        //             type: "yellow",
        //             color: "#f4d172"
        //         }
        //     },
        //     {
        //         id: 375,
        //         image: "",
        //         firstName: "Jack",
        //         lastName: "Willion",
        //         centerName: "Sai ram plaza ",
        //         city: "Indore",
        //         state: "MP",
        //         emailId: "magne@mailinator.com",
        //         dropOffTime: "12:43:00",
        //         lastUpdated: "2023-09-19T07:12:01.6513353",
        //         activityStatus: 3,
        //         status: {
        //             text: "System",
        //             type: "yellow",
        //             color: "#f4d172"
        //         }
        //     }
        // ]

        return response
    } catch (err) {
        console.log(err, '===========>err')
    }
})

const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {

    },
    extraReducers: {
        [activityInfo.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [activityInfo.fulfilled]: (state, action) => {
            state.loading = false
            state.success = true
            state.activityData = action.payload.data
        },
        [activityInfo.rejected]: (state, action) => {
            state.loading = false
            state.success = false
            // state.error = get(action.payload, 'response.data')
        },
    },

})

export default activitySlice.reducer
// export const {  } = authSlice.actions