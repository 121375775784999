import moment from 'moment';
import { dateFormat } from 'utils';

export const convertDateLastActive = (date, key) => {
    var convertedDate = ""
    if (date && key == 'lastActive') {
        convertedDate = moment(date + 'Z').format(`${dateFormat}, h:mm A`)
    } else if(date && key == 'droppedOff') {
        convertedDate = moment(date + 'Z').format('h:mm A')
    } else if(date && key == 'schedule'){
        convertedDate = moment(date, "HH:mm:ss").format("hh:mm A")
    } else {
        convertedDate = '-'
    }
    return convertedDate;
};
