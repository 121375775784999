import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { displaySuccessAlert, fieldSelectMsg } from 'utils';
import { ActionButton, TextInput } from 'views/components';

export default function ConfirmationModel({ open, handleClose, deleteUser, title, isModalFilter, columnFilterData, handleSubmitData, columnFilterName }) {
    const dispatch = useDispatch()
    const [checkBox, setCheckBox] = useState([]);
    const [searchInput, setSearchInput] = useState('')

    const handleChange = (e, val, index) => {
      const newData = checkBox.map((item, i) =>i === index ? { ...item, selected: !val?.selected } : item);
      setCheckBox(newData);
    };

    useEffect(() => {
        if(isModalFilter){
            const filteredArray = columnFilterData?.map(obj => {
                if (obj.selected === false) {
                    const { selected, ...rest } = obj;
                    return rest;
                }
                return obj;
            })
            setCheckBox(filteredArray);
        }
    }, []);

    const handleSubmitDatas = () => {
        let filterData = checkBox?.filter((res) => {
            if (res.selected == true || res.selected == false) {
                return res
            }
        })
        if (filterData.length == 0) {
            displaySuccessAlert(dispatch, 'error', fieldSelectMsg)
        } else {
            return handleSubmitData(filterData, columnFilterName)
        }
    }

    const isSelected = (data) => {
        const item = checkBox.find(box => box.name === data.name)
        if (item?.selected) {
            return item
        }
        return null
    }
    useEffect(() => {
        if (columnFilterData && columnFilterData?.length > 0) {
            const filteredCheckboxes = []
            columnFilterData.forEach(data => {
                const selectedItem = isSelected(data)
                if (selectedItem) {
                    if (selectedItem.selected === false) {
                        const { selected, ...rest } = selectedItem;
                        filteredCheckboxes.push(rest)
                    }
                    filteredCheckboxes.push(selectedItem)
                }
                else if (data?.name?.toLowerCase().includes(searchInput.toLocaleLowerCase())) {
                    if (data.selected === false) {
                        const { selected, ...rest } = data;
                        filteredCheckboxes.push(rest)
                    } else {
                        filteredCheckboxes.push(data)
                    }
                }

            })
            setCheckBox(filteredCheckboxes)
        }
    }, [searchInput])


    return (
        <div>
            <Dialog
                // fullScreen={fullScreen}
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    { isModalFilter ? <Typography variant='h3' sx={{ textTransform: 'capitalize'}}>
                        filter by {columnFilterName}
                    </Typography> : 
                    <Typography variant='h3'>
                        Delete {title}
                    </Typography>
                    }

                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isModalFilter ?
                            <>
                                <div style={{marginBottom:'8px !important', height:'70px'}} className='adduser-form'>
                                       <TextInput
                                        placeholder='search'
                                        type="text"
                                        value={searchInput}
                                        name="search"
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                    {checkBox && checkBox?.length <= 0 &&
                                        (<Typography sx={{textAlign:'center'}}>
                                            No Results found for <b>{searchInput && searchInput}</b>
                                        </Typography>)}
                                </div>
                        {
                           checkBox && checkBox?.length > 0 && checkBox.map((val, i) => {
                              return (
                                <div key={`${i} ${val?.name}`} className="">
                                  <input
                                    type="checkbox"
                                    name={val?.name}
                                    value={val?.selected}
                                    id={i}
                                    checked={val.selected}
                                    onChange={(event) => handleChange(event, val, i)}
                                  />
                                  <label htmlFor={i}>{val.name} {isModalFilter}</label>
                                </div>
                              );
                            })}
                            {checkBox?.length == 0 || checkBox?.length == undefined && (
                                <Typography variant='h5'>
                                    No Data Found
                                </Typography>
                            )}
                        </>
                    : 
                        <Typography variant='h5'>
                            Are you sure you want to delete?
                        </Typography>
                    }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ActionButton color='error' onClick={handleClose} cancel={true} title='Cancel' variant='contained' size="large" />
                    { isModalFilter ? 
                    <ActionButton color='secondary' onClick={handleSubmitDatas} cancel={false} title='Submit' fullWidth size="large" type="submit" variant="contained" />
                     : 
                    <ActionButton color='secondary' onClick={deleteUser} cancel={false} title='Delete' fullWidth size="large" type="submit" variant="contained" />}
                </DialogActions>
            </Dialog>
        </div>
    );
}