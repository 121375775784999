import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import Cookies from 'universal-cookie';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //
const cookie = new Cookies()
const NavGroup = ({ item, drawerToggle }) => {
  const theme = useTheme();
  const role = cookie.get('userData')?.role
  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        if (menu?.role?.includes(role)) {
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        }
        break
      case 'item':
        if (menu.role.includes(role)) {
          return <NavItem drawerToggle={drawerToggle} key={menu.id} item={menu} level={1} />;
        }
        break;
      default:
        return (<></>
        );
    }
  });

  return (
    <>

      <List
        subheader={
          item.title && (
            <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}

      </List>
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
