
import RoleWiseNavigation from "./RolewiseNavigation"
import { Outlet } from "react-router-dom"
import Cookies from "universal-cookie"
const cookie = new Cookies()
const RequiredPath = ({ allowedRoles }) => {
    const role = cookie.get('userData')?.role
    return (
        <>
            {allowedRoles.includes(role) ? <Outlet /> : <RoleWiseNavigation role={role} />}
        </>
    )
}
export default RequiredPath