import { insertUserData } from 'store/slices/loginSlice';
import Cookies from 'universal-cookie';
const cookies = new Cookies()
const logout = () => {
    cookies.remove("token");
    cookies.remove("userData");
    cookies.remove("adminData")
    window.location.pathname = '/';
};
export const insertToken = () => (dispatch) => {
    let data;
    if (cookies.get("userData")) {
        data = cookies.get("userData");
        dispatch(insertUserData(data));
    }
};
export default {
    logout, insertToken
};
