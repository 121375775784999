import { Box, CircularProgress } from "@mui/material"
import globalStylesCss from "globalStylesCss"


const Loading = () => {
    const classes = globalStylesCss()
    return (
        <>
            <Box className={`${classes.loaderWrapper} loader-wrapper`}>
                <CircularProgress size="2rem" className={classes.mainLoadingStyle} /> Loading...
            </Box>
        </>
    )
}

export default Loading 