// assets
import dashBoardIcon from '../assets/images/icons/activity.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      role: [1, 2, 3],
      icon: dashBoardIcon,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
