import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "utils/axiosInstance"
import { cutieeDeleteUrl, cutieeUpdate } from "utils/routesUrl"

const initialState = {
    cutieeData: [],
    singleCutieeData: {},
    loading: false,
    error: null,
    success: false
}

export const removeCutiee = createAsyncThunk(cutieeDeleteUrl, async (data) => {

    try {
        const response = await axiosInstance({
            url: cutieeDeleteUrl,
            method: "DELETE",
            data
        })
        response.data.id = data?.Id
        return response.data;
    } catch (error) {
        return error
    }
})

export const updateCutiee = createAsyncThunk(cutieeUpdate, async (data) => {
    try {
        const response = await axiosInstance({
            url: cutieeUpdate,
            method: "PUT",
            data
        })
        return response.data
    } catch (error) {
        return error?.response 
    }
})

const cutieeSlice = createSlice({
    name: 'cutiee',
    initialState,
    reducers: {
    },
    extraReducers: {
        [removeCutiee.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [removeCutiee.fulfilled]: (state, action) => {
            state.loading = false
        },
        [removeCutiee.rejected]: (state, action) => {
            state.loading = false
            state.success = false
        },
        [updateCutiee.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [updateCutiee.fulfilled]: (state, action) => {
            state.loading = false
        },
        [updateCutiee.rejected]: (state, action) => {
            state.loading = false
            state.success = false
        },
    },

})

export default cutieeSlice.reducer
// export const {  } = authSlice.actions