import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AddCenterBasicValidateSchema } from 'utils/validation';
import { clearStepData, firstStep } from 'store/slices/dayCareSlice';
import { handleGeocode } from 'utils/axiosInstance';
import { isEmpty } from 'lodash';
import { ActionButton, PhoneInput, SelectComponent, TextInput } from 'views/components';

const initialValues = {
    name: '',
    type: '',
    mobNo: '',
    emailId: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    coordinates: ''
}

const Basic = ({handleStepChange, classes, dispatch}) => {
    const [data, setData] = useState(initialValues)
    const fieldValues = useSelector((state)=> state.dateCareInfo.firstStepInfo);

    const typeOptions = [
        {name: 'Daycare', value: 1},
        {name: 'Preschool', value: 2},
        {name: 'Childcare', value: 3},
        {name: 'SchoolAge', value: 4},
        {name: 'Kindergarten', value: 5},
        {name: 'Nursery', value: 6},
    ]

    useEffect(()=>{
        if(fieldValues){
            setData(fieldValues)
        }
    }, [fieldValues])

    const hanldeClearBtn = () =>{
        dispatch(clearStepData())
    }

    return (
        <>
            <Formik
                initialValues={isEmpty(data) ? initialValues: data}
                validationSchema={AddCenterBasicValidateSchema}
                enableReinitialize={true}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    let cordinate
                    if(fieldValues.address == '' || values.address !== fieldValues.address){
                        cordinate = await handleGeocode(values, dispatch);
                    }
                    if(cordinate){
                        let newValues = {...values, coordinates:cordinate.latitude+','+cordinate.longitude }
                          dispatch(firstStep(newValues))
                          handleStepChange("",'2')
                      } else {
                        let newValues = {...values, coordinates:fieldValues.coordinates }
                        dispatch(firstStep(newValues))
                        handleStepChange("",'2')
                      }
                }}
            >

                {({ errors, handleBlur, handleChange, handleSubmit, setValues, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container justifyContent='space-between' className='adduser-form'>
                            <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                <Box>
                                    <TextInput
                                        type="text"
                                        value={values.name}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="Name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </Box>
                                <SelectComponent
                                    value={values.type}
                                    name="type"
                                    onBlur={handleBlur}
                                    onSelecteChange={handleChange}
                                    label="Type"
                                    items={typeOptions}
                                    error={touched.type && Boolean(errors.type)}
                                    helperText={touched.type && errors.type}
                                />
                            </Grid>
                            <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                <Box>
                                    <PhoneInput
                                        type="text"
                                        value={values.mobNo}
                                        name="mobNo"
                                        onBlur={handleBlur}
                                        label="Phone"
                                        // defaultCountry="US"
                                        onChange={(val) => setValues({ ...values, mobNo: val })}
                                        error={touched.mobNo && Boolean(errors.mobNo)}
                                        helperText={touched.mobNo && errors.mobNo}
                                    />
                                </Box>
                                <Box>
                                    <TextInput
                                        type="text"
                                        value={values.emailId}
                                        name="emailId"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="Email"
                                        error={touched.emailId && Boolean(errors.emailId)}
                                        helperText={touched.emailId && errors.emailId}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='space-between' className='adduser-form add-cutiee-border'>
                            <Grid item md={12} xs={12} >
                                <TextInput
                                    type="text"
                                    value={values.address}
                                    name="address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Address"
                                    error={touched.address && Boolean(errors.address)}
                                    helperText={touched.address && errors.address}
                                />
                            </Grid>
                            <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                <Box>
                                    <TextInput
                                        type="text"
                                        value={values.city}
                                        name="city"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="City"
                                        error={touched.city && Boolean(errors.city)}
                                        helperText={touched.city && errors.city}
                                    />
                                </Box>
                                <Box>
                                    <TextInput
                                        type="text"
                                        value={values.state}
                                        name="state"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="State"
                                        error={touched.state && Boolean(errors.state)}
                                        helperText={touched.state && errors.state}
                                    />
                                </Box>
                            </Grid>
                            <Grid container justifyContent='space-between' md={12} xs={12} className='adduser-fields-wrapper'>
                                <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                    <Box>
                                        <TextInput
                                            type="text"
                                            value={values.zip}
                                            name="zip"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Zip"
                                            error={touched.zip && Boolean(errors.zip)}
                                            helperText={touched.zip && errors.zip}
                                        />
                                    </Box>
                                    <Box>
                                        <TextInput
                                            type="text"
                                            value={values.country}
                                            name="country"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            label="Country"
                                            error={touched.country && Boolean(errors.country)}
                                            helperText={touched.country && errors.country}
                                        />
                                    </Box>
                                </Grid>
                                <Box>
                                    <TextInput
                                        type="text"
                                        value={values.coordinates}
                                        name="coordinates"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="Coordinates"
                                        error={touched.coordinates && Boolean(errors.coordinates)}
                                        helperText={touched.coordinates && errors.coordinates}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid container className={classes.updateUserBtnContainer} mt={2} >
                                <Box display='flex'>
                                    <Link to='/centers' className={classes.linkTextDecoration}>
                                        <ActionButton color='error' onClick={hanldeClearBtn} cancel={true} title='Cancel' variant='contained' size="large" />
                                    </Link>
                                    <ActionButton color='secondary' cancel={false} title='Next' disableElevation fullWidth size="large" type="submit" variant="contained" />
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default Basic