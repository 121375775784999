import React from 'react';
import styled from 'styled-components';

// Styled Components
const StyledSwitchButton = styled.div`
  
  align-items: center;
  button {
    width: 60px;
    height: ${(props) => (props.active ? '34px' : '34px')};
    background-color: ${(props) => (props.active ? '#bf5af2' : '#999999')};
    border-radius: 44px;
    border: 1px solid ${(props) => (props.active ? '#bf5af2' : '#ECECEC')};
    box-sizing: border-box;
    padding: 0;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    outline: none;
    
    &::after {
      content: '';
      width: 32px;
      height: 32px;
      background-color: #FFFFFF;
      border-radius: 50%;
      box-shadow: 0px 1px 3px rgba(30, 30, 30, 0.3);
      transition: all 300ms ease-in-out;
      transform: ${(props) =>
        props.active ? 'translate(16px)' : 'translate(0)'};
      display: block;
      margin-left: ${(props) => (props.active ? '7px' : '1px')};
    }
  }
`;

// Switch Button Component
const SwitchButton = (props) => (
  <StyledSwitchButton active={props.active} className='toggle-btn'>
    <button type={props.type} style={{marginTop: '5px'}} onClick={props.clicked}></button>
  </StyledSwitchButton>
);

export default SwitchButton;
