import { InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MuiTelInput } from "mui-tel-input";

const MuiTelInputStyled = styled(MuiTelInput)(({ theme }) => ({
    '& .MuiTelInput-TextField ': {
        borderColor: 'red'
    },
    '& label': {
        color: theme.palette.input.main,
    },
    '& label.Mui-focused': {
        color: theme.palette.input.main,
    },
    "&:hover label": {
        color: theme.palette.input.main,
    },
    '& .MuiOutlinedInput-root': {
        '& Input': {
            height: '12px',
            backgroundColor: '#ffffff',
            color: '#434343'
        },
        '& fieldset': {
            borderColor: theme.palette.input.light,
            borderRadius: '0px',
        },
        '&:hover fieldset': {
            borderColor: theme.palette.input.light,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.input.light
        },
    },
}));

const PhoneInput = ({ label, onChange, ...rest }) => {
    return (
        <>
            <InputLabel
                htmlFor="password-field" sx={{ color: '#080808', marginBottom: '4px' }}>{label}</InputLabel>
            <MuiTelInputStyled
                onChange={(v, c) => onChange(v, c)}
                sx={{ width: '100%' }}
                {...rest}
            />
        </>
    )
}

export default PhoneInput