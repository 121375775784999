import { useEffect, useState } from 'react';
import { Formik } from "formik"
import { AddUserValidateSchema, UpdatedUserValidateSchema } from 'utils/validation';
import { ActionButton, Loading, PasswordInput, PhoneInput, SvgIcons, SwitchButton, TextInput } from 'views/components';
import { Box, Grid } from '@mui/material';
import { addUser, removeUser, updateUser } from 'store/slices/userSlice';
import { get } from 'lodash';
import activityIcon from '../../../assets/images/icons/delete.svg';
import { Link } from "react-router-dom";
import ConfirmationModel from 'views/confirmationModel/confirmationModel';
import { addedSucc,updatedSucc, displaySuccessAlert  } from 'utils';

const initialValuesUser = {
    firstName: '',
    lastName: '',
    emailId: '',
    mobNo: '',
    password: '',
    userType: 0,
    registeredBy: 0,
}

const UserPersonal = ({ userData, params, classes, dispatch, navigate }) => {
    const id = params?.id || ''
    const [userId, setUserId] = useState('')
    const [open, setOpen] = useState(false);
    const [userActive, setUserActive] = useState();
    const [isChangeActive, setIsChangeActive] = useState(false)
    const [user, setUser] = useState(initialValuesUser)

    useEffect(() => {
        if (id) {
            let { firstName, lastName, mobNo, emailId, active} = userData?.singleUserData
            let data = {
                firstName: firstName,
                lastName: lastName,
                mobNo: mobNo,
                emailId: emailId,
                active: active
            }
            if (isChangeActive) {
                setUserActive(userActive)
            } else {
                setUserActive(get(userData, 'singleUserData.active'))
            }
            setUser({ ...user, ...data })
        } else {
            setUser({ ...user, ...initialValuesUser })
        }
    }, [id, userData])

    const handleOpenDelete = () => {
        setUserId(params?.id)
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const deleteUser = () => {
        setOpen(false);
        let data = {
            Id: userId
        }
        dispatch(removeUser(data)).then((res) => {
            if (res?.payload?.success) {
                displaySuccessAlert(dispatch, 'success',get(res, 'payload.message'))
                navigate('/users')
            } else {
                displaySuccessAlert(dispatch, 'error',get(res, 'payload.message'))
            }
        })
    };

    const toggleSwitchButton = () => {
        setIsChangeActive(true)
        setUserActive(!userActive);
    } 

    return (
        <>
        {get(userData, 'loading') && userData?.userData?.length ? 
            <Box sx={{position: 'relative'}}>
                <Loading />
            </Box>  : 
            <Formik
                initialValues={user}
                validationSchema={params?.id ? UpdatedUserValidateSchema : AddUserValidateSchema}
                enableReinitialize={true}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (id) {
                            values.UserId = id
                            values.active = userActive
                        }
                        let userAction = id ? updateUser(values) : addUser(values)
                        dispatch(userAction).then((res) => {
                            if (get(res, 'payload.token') || get(res, "payload.success")) {
                                let messasge = id ? `User ${updatedSucc}` : `User ${addedSucc}`
                                displaySuccessAlert(dispatch, 'success',messasge)
                                navigate('/users')
                            } else if (res?.payload?.data?.errors) {
                                displaySuccessAlert(dispatch, 'error',get(res, 'payload.data.title'))
                            } else {
                                displaySuccessAlert(dispatch, 'error',get(res, 'payload.data.message'))
                            }
                        })
                    } catch (err) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >

                {({ errors, handleBlur, handleChange, handleSubmit, setValues, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container justifyContent='space-between' flexDirection='column' className='adduser-form'>
                            <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                <Box component="div" className='field-wrapper'  >
                                    <TextInput
                                        type="text"
                                        value={values.firstName}
                                        name="firstName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="First name"
                                        error={touched.firstName && Boolean(errors.firstName)}
                                        helperText={touched.firstName && errors.firstName}
                                    />
                                </Box>
                                <Box component="div" className='field-wrapper'>
                                    <TextInput
                                        type="text"
                                        value={values.lastName}
                                        name="lastName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="Last name"
                                        error={touched.lastName && Boolean(errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                <Box component="div" className='field-wrapper'>
                                    <PhoneInput
                                        type="text"
                                        value={values.mobNo}
                                        name="mobNo"
                                        onBlur={handleBlur}
                                        label="Phone"
                                        defaultCountry="US"
                                        onChange={(val) => setValues({ ...values, mobNo: val })}
                                        error={touched.mobNo && Boolean(errors.mobNo)}
                                        helperText={touched.mobNo && errors.mobNo}
                                    />
                                </Box>
                                <Box component="div" className='field-wrapper'>
                                    <TextInput
                                        type="text"
                                        value={values.emailId}
                                        name="emailId"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="Email address"
                                        error={touched.emailId && Boolean(errors.emailId)}
                                        helperText={touched.emailId && errors.emailId}
                                    />
                                </Box>
                            </Grid>
                            {!params?.id ? 
                                <Grid item md={12} xs={12} className='adduser-fields-wrapper'>
                                    <Box component="div" className='field-wrapper'>
                                        <PasswordInput
                                            name="password"
                                            value={values.password}
                                            label="Password"
                                            onInputChange={handleChange}
                                            labelError={touched.password && errors.password}
                                            labelColor={touched.password && errors.password && 'error'}
                                            error={touched.password && Boolean(errors.password)}
                                            helperText={touched.password && errors.password}
                                            sx={{ mb: 0 }}
                                        />
                                    </Box>
                            </Grid> : null}
                            <Grid container className={`${classes.updateUserBtnContainer} control-wrapper`} sx={{ justifyContent: params?.id ? 'space-between !important' : '' }}>
                                <Box display='flex' alignItems='center' className="controls">
                                    {params?.id ? <>
                                        <Box className={`${classes.userDelBtn} delete-btn`}  sx={{ marginRight: '20px' }} onClick={handleOpenDelete}>
                                            <SvgIcons src={activityIcon} className={classes.delIconStyle} />
                                        </Box>
                                        <SwitchButton type="button" active={userActive} clicked={toggleSwitchButton} ></SwitchButton>
                                    </>
                                        : null}
                                </Box>
                                <Box display='flex' className='btn-wrapper'>
                                    <Link to='/users' className={classes.linkTextDecoration}>
                                        <ActionButton color='error' cancel={true} title='Cancel' variant='contained' size="large" />
                                    </Link>
                                    <ActionButton color='secondary' cancel={false} title='Save changes' isLoading={userData?.updateLoading} disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" />
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        }
            {open ? <ConfirmationModel title={'User'} open={open} handleClose={handleClose} deleteUser={deleteUser}  /> : null}
        </>
    )
}

export default UserPersonal

