import { DataGrid } from "@mui/x-data-grid"
import { Box, CardContent, Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import ConfirmationModel from "views/confirmationModel/confirmationModel"
import ActionButton from "./ActionButton"
import { useTheme } from '@mui/material/styles';
import globalStylesCss from "globalStylesCss"
import SearchFilter from "./searchFilter"
import { CSVLink } from "react-csv"
import Loading from "./loading"
import FilterList from "./FilterList"

const DataTable = ({ isStickyHeader, url, title, conirmationTitle, open, handleClose, deleteUser, filename, pagination,exportHeaders, exportCsv, rows, columns, handledelete, searchData, handleFilter, tableHeader, isAdd, handleChangeAPI, paginationModel, handleSearchFilter, isApiSearch, handleSortChange, isModalFilter, columnFilterData, handleSubmitData, columnFilterName, count, isLoading, isClearBtn, handleClearFilter, isClearSearchInput, pageSizeOptions, hideFooterPagination}) => {
    const theme = useTheme()
    const classes = globalStylesCss()
    let paginationData = {
        page: paginationModel?.page,
        pageSize: paginationModel?.limit || paginationModel?.pageSize
    }
    const  CustomNoRowsOverlay = () => {
        return (
            <Box sx={{position: 'relative', display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}>
                { isLoading ? <Loading /> : 
                <Box sx={{ fontSize: '20px', display: 'flex', justifyContent: 'center'}}>
                    No Data Found
            </Box>}
            </Box>
        )
    }
    return (
        <>
            <Grid container spacing={0} direction="column" className={`${!tableHeader && 'no-tableHeader'} table-grid`} alignItems="center" justifyContent="center">
                <Grid item xs={3} sx={{ width: '100%' }} className="table-header">
                    {tableHeader ? <Grid container spacing={0} alignItems="center" className="table-header-wrapper" justifyContent="space-between">
                        <Grid item xs={3} >
                            <Typography variant="h2" >{title}</Typography>
                        </Grid>
                        <Grid item xs={9} display='flex' alignItems='center' className="header-actions" justifyContent='flex-end' >
                            { isApiSearch ?<>
                                <SearchFilter paginationModel={paginationModel} searchData={searchData} isApiSearch={isApiSearch} handleSearchFilter={handleSearchFilter} isClearSearchInput={isClearSearchInput} handleFilter={handleFilter}/> 
                                <Box className="header-btn-wrapper">
                                    {isClearBtn && <ActionButton color='error' onClick={handleClearFilter}  title='Clear' isUserBtn={true} variant='contained' size="small" />}
                                    {isAdd ? 
                                    <><Link to={url} className={classes.linkTextDecoration}>
                                        <ActionButton color='error'  title='Add' isUserBtn={true} variant='contained' size="small" />
                                    </Link>
                                    </> : null}
                                    {
                                        exportCsv?
                                        <CSVLink data={rows} headers={exportHeaders} filename={filename}>
                                            <ActionButton color='error' title='Export' isUserBtn={true} variant='contained' size="small" />
                                        </CSVLink>
                                        : null 
                                    }
                                </Box>
                            </>
                            : <>
                                <SearchFilter searchData={searchData} handleFilter={handleFilter} handleSearchFilter={handleSearchFilter}/>
                                <Box className="header-btn-wrapper">
                                {isAdd ? 
                                <><Link to={url} className={classes.linkTextDecoration}>
                                    <ActionButton color='error'  title='Add' isUserBtn={true} variant='contained' size="small" />
                                </Link>
                                </> : null}
                                {
                                    exportCsv?
                                    <CSVLink data={rows} headers={exportHeaders} filename={filename}>
                                        <ActionButton color='error' title='Export' isUserBtn={true} variant='contained' size="small" />
                                    </CSVLink>
                                    : null 
                                }
                            </Box>
                            </>

                            }
                        </Grid>
                    </Grid> : null}  
                    {paginationModel?.filters && paginationModel?.filters?.length > 0 &&
                        <Box sx={{ mt: 2, borderColor: 'grey.400' }}>
                            <FilterList filters={paginationModel?.filters} />
                        </Box>} 
                        <CardContent sx={{
                            padding: '20px 0 ',
                            '& .rows': {
                                textTransform: 'capitalize'
                            },
                            '& .email': {
                                textTransform: 'lowerCase',
                            },
                        }} >
                            {open ? <ConfirmationModel title={conirmationTitle} open={open} handleClose={handleClose} deleteUser={deleteUser} handledelete={handledelete} isModalFilter={isModalFilter} columnFilterData={columnFilterData} handleSubmitData={handleSubmitData} columnFilterName={columnFilterName} /> : null}
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                 
                                // disableColumnMenu={true}
                                disableColumnSelector={true}
                                disableRowSelectionOnClick={true}
                                className={`custom-datatable table-wrapper ${pagination && 'pagination'}`}
                                sx={{
                                    height: isStickyHeader && 'calc(100vh - 180px)',
                                    backgroundColor: theme.palette.primary.main,
                                    margin: 'auto',
                                    '& .super-app-theme--header': {
                                        backgroundColor: theme.palette.primary.main,
                                        color: '#969696',
                                        // position:'sticky'
                                    },
                                    '& .super-app-theme--activityRow': {
                                        color: '#ADADAD',
                                      },
                                    '& .super-app-theme--activityName': {
                                        textTransform: 'capitalize',
                                        color: '#000000',
                                        fontWeight: '400',
                                    },
                                    '& .super-app-theme--addressStyle': {
                                        color: '#4B4B4B',
                                    },
                                    '& .super-app-theme--userName': {
                                        textTransform: 'capitalize'
                                    },
                                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' },
                                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                                    {
                                      outline: "none",
                                    },
                                    '& .MuiDataGrid-virtualScrollerContent':  { minHeight: !rows.length ? '130px !important' : 'auto'}
                                }}
                                paginationMode={count? 'server': 'client'}
                                rowCount={count ? count : rows.length}
                                pageSizeOptions={pageSizeOptions}
                                paginationModel={paginationData}
                                onPaginationModelChange={handleChangeAPI}
                                onSortModelChange={handleSortChange}
                                loading={isLoading}
                                slots={{
                                    noRowsOverlay: CustomNoRowsOverlay,
                                  }}
                                  hideFooterPagination={hideFooterPagination}
                                  />
                        </CardContent>
                </Grid>
            </Grid>
        </>
    )
}
export default DataTable