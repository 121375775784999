import React from "react";
import { Grid, Typography } from "@mui/material";
const CardHeading = ({ title }) => {
    return (
        <Grid item xs={3} mb='20px' >
            <Typography variant="h2" >{title}</Typography>
        </Grid>
    );
};

export default CardHeading;