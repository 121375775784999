// assets
import activityIcon from '../assets/images/icons/Path48.svg';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const activity = {
  id: 'activity',
  type: 'group',
  children: [
    {
      id: 'activity',
      title: 'Cutiees',
      type: 'item',
      url: '/activity',
      role: [1, 2, 3],
      icon: activityIcon,
      breadcrumbs: false
    }
  ]
};

export default activity;
